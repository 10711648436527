import gql from 'graphql-tag'

export const SavePartnerPricingTest = gql`
  mutation ($input: PartnerPricingTestInput!) {
    savePartnerPricingTest(input: $input) {
      test_code
      test_name
      ls_price
      discount
      adjusted_price
      is_adjusted_price_overridden
      retail_price
    }
  }
`
