import React, { useState } from 'react'

import { InputField, useForm } from '@labsavvyapp/ui-components'

import style from './PasswordField.module.css'
import LockIconWhite from './lock-icon-white.svg'
import LockIconWGreen from './lock-icon-green.svg'

const PASSWORD_STRENGTH_REG_EX = new RegExp(
  '(?=^.{8,}$)((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[0-9])(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*',
)

export default function PasswordField({ name, placeholder }) {
  const [isValid, setValid] = useState(false)
  const { updateField } = useForm()

  function handleChange(value) {
    const validPassword = PASSWORD_STRENGTH_REG_EX.test(value)
    setValid(validPassword)
    updateField(name, { value, error: !validPassword })
  }

  return (
    <div className={style.passwordField}>
      <InputField
        type="password"
        name={name}
        placeholder={placeholder}
        className={style.inputField}
        showErrors={false}
        onChange={handleChange}
      />
      {isValid ? (
        <img
          className={style.icon}
          alt="lock-icon-green"
          src={LockIconWGreen}
        />
      ) : (
        <img className={style.icon} alt="lock-icon-white" src={LockIconWhite} />
      )}
    </div>
  )
}
