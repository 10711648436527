import React, { useState, useEffect } from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { Icon } from 'semantic-ui-react'

import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { GetAllConfigs } from '../../../graphql/settings/queries'
import style from './ConfigurationPage.module.css'
import AddEditConfigurationPage from './AddEditConfigurationPage'
import ConfigurationRow from './ConfigurationRow'

import _ from 'lodash'

export default function ConfigurationPage() {
  const { search: locationSearch } = useLocation()
  const query = new URLSearchParams(locationSearch)
  const [search, setSearch] = useState(query.get('search') || '')

  useEffect(() => {
    setSearch(query.get('search' || ''))
  }, [locationSearch])

  const { push } = useHistory()

  const { data: configData, loading } = useQuery(GetAllConfigs, {
    fetchPolicy: 'network-only',
  })

  const [selectedConfig, setSelectedConfig] = useState()
  const handleEditConfiguration = (config) => {
    setSelectedConfig(config)
  }

  return (
    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
      <div style={{ width: '100%' }}>
        {configData &&
          configData.getAllConfig.map((config) => {
            return <ConfigurationRow data={config} />
          })}
      </div>
    </div>
  )
}
