import React, { useState, useEffect } from 'react'

import { Dropdown } from 'semantic-ui-react'
import { Search, Button } from '@labsavvyapp/ui-components'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { useHistory, useLocation, useParams } from 'react-router'

import { ListPartnerProjects } from '../../../graphql/partner/queries.js'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { PARTNERS } from '../../../config/routes'
import { SORT_BY_OPTIONS } from './constants'
// import ActionIconsMenu from '../ActionIconsMenu/ActionIconsMenu'
import ProjectsList from './ProjectsList'
import { updateURLParameter } from '../../../utils/urls'
import PartnerHeader from '../components/PartnerHeader/PartnerHeader'
import style from './ProjectsPage.module.css'

function getQueryVariables(sortBy, search) {
  const variables = {
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = SORT_BY_OPTIONS.nameZA.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function ProjectsPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()
  const { partnerId } = useParams()

  const query = new URLSearchParams(locationSearch)

  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  const { data, refetch, networkStatus } = useQuery(ListPartnerProjects, {
    variables: {
      partnerId,
    },
    notifyOnNetworkStatusChange: true,
  })
  const projectsData = camelizeKeys(data)

  useEffect(() => {
    refetch({
      ...getQueryVariables(sortBy, search),
      partnerId,
      page: 1,
    })
  }, [sortBy, search, partnerId, refetch])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`?${urlParams}`)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`?${urlParams}`)
    setSearch(value)
  }

  return (
    <MainContainer>
      <PartnerHeader
        backButtonText="Back to Partners"
        backButtonURL={PARTNERS.list}
      />

      <header className={style.projectsListHeader}>
        <h1 className={style.pageTitle}>Projects</h1>

        <div className={style.tools}>
          <div>
            <span>Sort by:</span>
            <Dropdown
              selection
              data-test="sort-dropdown"
              className={style.dropDown}
              value={sortBy}
              options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                text: SORT_BY_OPTIONS[option].text,
                value: SORT_BY_OPTIONS[option].key,
                key: SORT_BY_OPTIONS[option].key,
              }))}
              onChange={handleSortChange}
            />
          </div>

          <Search
            className={style.search}
            placeholder="Search Projects"
            showNoResults={false}
            searchText={search}
            onSearchChange={handleSearchChange}
          />

          <Button
            variant="primary"
            onClick={() =>
              push(PARTNERS.projects.new.replace(':partnerId', partnerId))
            }
          >
            New Project
          </Button>
        </div>
      </header>

      <div className={style.contentContainer}>
        {/* <ActionIconsMenu /> */}
        <ProjectsList
          data={projectsData}
          loading={networkStatus === 1 || networkStatus === 3}
        />
      </div>
    </MainContainer>
  )
}
