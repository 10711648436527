import React from 'react'

import { Grid } from 'semantic-ui-react'
import {
  Form,
  InputField,
  SubmitButton,
  useSavingModals,
  validate,
} from '@labsavvyapp/ui-components'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'

import sharedStyles from '../../../../styles/shared.module.css'
import {
  CreatePartnerProject,
  UpdatePartnerProject,
} from '../../../../graphql/partner/mutations.js'
import {
  GetPartnerProject,
  GetPartnerProjectProfile,
} from '../../../../graphql/partner/queries.js'
import { PARTNERS } from '../../../../config/routes'
import { useIntl } from 'react-intl'

export default function ProfileTab() {
  const { push } = useHistory()
  const { partnerId, projectId } = useParams()
  const { formatMessage } = useIntl()

  // Fetch project data
  const { data } = useQuery(GetPartnerProjectProfile, {
    variables: {
      partnerId,
      projectId,
    },
    skip: !projectId,
  })

  // Create/ Update project
  const [createPartnerProject] = useMutation(CreatePartnerProject)
  const [updatePartnerProject] = useMutation(UpdatePartnerProject, {
    refetchQueries: [
      {
        query: GetPartnerProject,
        variables: {
          partnerId,
          projectId,
        },
      },
    ],
  })

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the project, please wait...",
    savedMessage: 'The project has been saved.',
    callback: async ({ name, account }) => {
      if (!projectId) {
        const response = await createPartnerProject({
          variables: { partnerId, data: { name, account } },
        })
        push(
          PARTNERS.projects.admin.base
            .replace(':partnerId', partnerId)
            .replace(':projectId', response.data.createPartnerProject._id)
            .concat('/general/profile'),
        )
      } else {
        updatePartnerProject({
          variables: { partnerId, projectId, data: { name, account } },
        })
      }
    },
  })

  async function handleFormSubmit({ name, account }) {
    showModals({ name, account })
  }

  return (
    <>
      <Form
        initialFormData={data && data.getPartnerProject}
        onSubmit={handleFormSubmit}
        intl={{ formatMessage }}
      >
        <Grid>
          <Grid.Row computer={8} tablet={16}>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="name"
                label="Account Name"
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row computer={8} tablet={16}>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="account"
                label="Quest Diagnostics Sub-Account Number"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SubmitButton className={sharedStyles.marginTop10}>
                {projectId ? 'Save Changes' : 'Create Project'}
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
