import { gql } from '@apollo/client'

export const GetAllConfigs = gql`
  query GetAllConfigs {
    getAllConfig {
      _id
      domain
      subdomain
      category
      subcategory
      code
      subcode
      name
      description
      placeholder
      value
      value2
      value3
      created_at
      updated_at
      deleted_at
    }
  }
`
