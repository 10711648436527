export const TIME_ZONE_OPTIONS = Object.freeze([
  {
    value: 'Pacific/Honolulu',
    text: '(GMT -10:00) Hawaii Time',
  },
  {
    value: 'America/Anchorage',
    text: '(GMT -9:00) Alaska Time',
  },
  {
    value: 'America/Los_Angeles',
    text: '(GMT -8:00) Pacific Time',
  },
  {
    value: 'America/Denver',
    text: '(GMT -7:00) Mountain Time',
  },
  {
    value: 'America/Chicago',
    text: '(GMT -6:00) Central Time',
  },
  {
    value: 'America/New_York',
    text: '(GMT -5:00) Eastern Time',
  },
])

export const CONSUMER_NAME_OPTIONS = Object.freeze([
  { text: 'Patients', value: 'patient' },
  { text: 'Clients', value: 'client' },
])

export const SWITCHES_ON_OFF = Object.freeze([
  { text: 'On', value: 'true' },
  { text: 'Off', value: 'false' },
])
