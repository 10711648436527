import React from 'react'

import { Card } from '@labsavvyapp/ui-components'
import { useQuery } from '@apollo/react-hooks'

import MainContainer from '../../../../components/MainContainer/MainContainer'
import { GetMe } from '../../../../graphql/user/queries.js'
import style from './SecurityPage.module.css'

function SecurityPage() {
  const { data } = useQuery(GetMe)
  const userDisplayName = data && data.getMe.name.display

  return (
    <MainContainer>
      <Card className={style.card}>
        <h1 className={style.title}>Welcome, {userDisplayName}!</h1>
        <p className={style.paragraph}>
          We are currently working on improving your LabSavvy Admin experience.
          In a future release, this section will provide you with an overview of
          all important security settings.
        </p>
      </Card>
    </MainContainer>
  )
}

export default SecurityPage
