import React from 'react'
import { Switch, Route } from 'react-router'

import { PARTNERS } from '../config/routes'
import MainLayout from '../components/MainLayout/MainLayout'
import TabsLayout from '../components/TabsLayout/TabsLayout'

// 404 Page
import Error404Page from '../pages/Errors/Error404Page'

// Partners
import PartnerHeader from '../pages/Partners/components/PartnerHeader/PartnerHeader'

// Partner admin tabs
import PartnerContactInfoTab from '../pages/Partners/PartnerAdmin/General/ContactInfoTab'
import PartnerProfileTab from '../pages/Partners/PartnerAdmin/General/ProfileTab'

import PartnerBrandingTab from '../pages/Partners/PartnerAdmin/Customization/BrandingTab'
import PartnerSettingsTab from '../pages/Partners/PartnerAdmin/Customization/SettingsTab'

import PartnerTeamMembersTab from '../pages/Partners/PartnerAdmin/TeamMembers/TeamMembersTab'
import PartnerTeamMemberFormTab from '../pages/Partners/PartnerAdmin/TeamMembers/FormTab'

import PartnerPlanTab from '../pages/Partners/PartnerAdmin/Billing/PlanTab'
import PartnerInvoicesTab from '../pages/Partners/PartnerAdmin/Billing/InvoicesTab'

import PartnerPricingTab from '../pages/Partners/PartnerAdmin/PartnerPricing/PartnerPricingTab'

// Partner Projects
import ProjectHeader from '../pages/Partners/components/ProjectHeader/ProjectHeader'
import ProjectsPage from '../pages/Partners/Projects/ProjectsPage'

// Project admin tabs
import ProjectProfileTab from '../pages/Partners/ProjectAdmin/General/ProfileTab'
import ProjectApprovingPhysicianTab from '../pages/Partners/ProjectAdmin/General/ApprovingPhysicianTab'

import ProjectBrandingTab from '../pages/Partners/ProjectAdmin/Customization/BrandingTab'
import ProjectSettingsTab from '../pages/Partners/ProjectAdmin/Customization/SettingsTab'

import ProjectMembersTab from '../pages/Partners/ProjectAdmin/TeamMembers/ProjectMembersTab'
import ProjectInviteProjectMemberTab from '../pages/Partners/ProjectAdmin/TeamMembers/InviteProjectMemberTab'

import ProjectPackagesTab from '../pages/Partners/ProjectAdmin/Packages/PackagesTab'
import ProjectAddPackageTab from '../pages/Partners/ProjectAdmin/Packages/AddPackageTab'

import ProjectLabsTab from '../pages/Partners/ProjectAdmin/Labs/LabsTab'
import ProjectAddLabTab from '../pages/Partners/ProjectAdmin/Labs/AddLabTab'

import SecurityPage from '../pages/Partners/ProjectAdmin/Security/SecurityPage'
import Notifications from '../pages/Partners/ProjectAdmin/Notifications/Notifications'

import MiscPricingTab from '../pages/Partners/PartnerAdmin/MiscPricing/MiscPricingTab'
import MiscPricingAddForm from '../pages/Partners/PartnerAdmin/MiscPricing/FormTab'

// New Partner
const newPartnerAdminMenuItems = [
  {
    name: 'General',
    url: `${PARTNERS.admin.base}/general`,
    tabs: [
      {
        name: 'Profile',
        url: `${PARTNERS.admin.section}/profile`,
        Content: PartnerProfileTab,
      },
    ],
  },
]

// Partner
const partnerAdminMenuItems = [
  {
    name: 'General',
    url: `${PARTNERS.admin.base}/general`,
    tabs: [
      {
        name: 'Profile',
        url: `${PARTNERS.admin.section}/profile`,
        Content: PartnerProfileTab,
      },
      {
        name: 'Contact Info',
        url: `${PARTNERS.admin.section}/contact-info`,
        Content: PartnerContactInfoTab,
      },
    ],
  },
  {
    name: 'Customization',
    url: `${PARTNERS.admin.base}/customization`,
    tabs: [
      {
        name: 'Branding',
        url: `${PARTNERS.admin.section}/branding`,
        Content: PartnerBrandingTab,
      },
      {
        name: 'Settings',
        url: `${PARTNERS.admin.section}/settings`,
        Content: PartnerSettingsTab,
      },
    ],
  },
  {
    name: 'Partner Pricing',
    url: `${PARTNERS.admin.base}/partner-pricing`,
    tabs: [
      {
        name: 'Partner Pricing',
        url: `${PARTNERS.admin.base}/partner-pricing`,
        Content: PartnerPricingTab,
      },
    ],
  },
  {
    name: 'Miscellaneous Pricing',
    url: `${PARTNERS.admin.base}/misc-pricing`,
    tabs: [
      {
        name: 'Miscellaneous Pricing',
        Content: MiscPricingTab,
        url: `${PARTNERS.admin.base}/misc-pricing`,
      },
      {
        name: '> Add',
        Content: MiscPricingAddForm,
        visibleURLCondition: `${PARTNERS.admin.base}/misc-pricing/add`,
        url: `${PARTNERS.admin.base}/misc-pricing/add`,
      },
    ],
  },
  {
    name: 'Team Members',
    url: `${PARTNERS.admin.base}/team-members`,
    tabs: [
      {
        name: 'Team Members',
        Content: PartnerTeamMembersTab,
        url: `${PARTNERS.admin.base}/team-members`,
      },
      {
        name: '> Invite',
        Content: PartnerTeamMemberFormTab,
        visibleURLCondition: `${PARTNERS.admin.base}/team-members/invite`,
        url: `${PARTNERS.admin.base}/team-members/invite`,
      },
      {
        name: 'Edit',
        Content: PartnerTeamMemberFormTab,
        visibleURLCondition: PARTNERS.admin.editTeamMember,
        url: PARTNERS.admin.editTeamMember,
      },
    ],
  },
  {
    name: 'Billing',
    url: `${PARTNERS.admin.base}/billing`,
    tabs: [
      {
        name: 'Plan',
        Content: PartnerPlanTab,
        url: `${PARTNERS.admin.section}/'plan`,
      },
      {
        name: 'Invoices',
        Content: PartnerInvoicesTab,
        url: `${PARTNERS.admin.section}/invoices`,
      },
      {
        name: 'Payment Method',
        Content: null,
        url: `${PARTNERS.admin.section}/payment-method`,
      },
    ],
  },
  {
    name: 'Security',
    url: `${PARTNERS.admin.base}/security`,
    tabs: [
      {
        name: 'Security',
        Content: SecurityPage,
        url: `${PARTNERS.admin.base}/security`,
      },
    ],
  },
]

// New Project
const newProjectAdminMenuItems = [
  {
    name: 'General',
    url: `${PARTNERS.projects.admin.base}/general`,
    tabs: [
      {
        name: 'Profile',
        url: `${PARTNERS.projects.admin.section}/profile`,
        Content: ProjectProfileTab,
      },
    ],
  },
]

// Project
const projectAdminMenuItems = [
  {
    name: 'General',
    url: `${PARTNERS.projects.admin.base}/general`,
    tabs: [
      {
        name: 'Profile',
        url: `${PARTNERS.projects.admin.section}/profile`,
        Content: ProjectProfileTab,
      },
      {
        name: 'Approving Physician',
        url: `${PARTNERS.projects.admin.section}/approving-physician`,
        Content: ProjectApprovingPhysicianTab,
      },
    ],
  },
  {
    name: 'Customization',
    url: `${PARTNERS.projects.admin.base}/customization`,
    tabs: [
      {
        name: 'Branding',
        url: `${PARTNERS.projects.admin.section}/branding`,
        Content: ProjectBrandingTab,
      },
      {
        name: 'Settings',
        url: `${PARTNERS.projects.admin.section}/settings`,
        Content: ProjectSettingsTab,
      },
    ],
  },
  {
    name: 'Labs',
    url: `${PARTNERS.projects.admin.base}/labs`,
    tabs: [
      {
        name: 'Labs',
        Content: ProjectLabsTab,
        url: `${PARTNERS.projects.admin.section}/labs`,
      },
      {
        name: '> Add Lab',
        Content: ProjectAddLabTab,
        visibleURLCondition: `${PARTNERS.projects.admin.base}/labs/add-lab`,
        url: `${PARTNERS.projects.admin.base}/labs/add-lab`,
      },

      {
        name: '> Edit Lab',
        Content: ProjectAddLabTab,
        visibleURLCondition: `${PARTNERS.projects.admin.base}/labs/edit-lab`,
        url: `${PARTNERS.projects.admin.base}/labs/edit-lab`,
      },
    ],
  },
  {
    name: 'Team Members',
    url: `${PARTNERS.projects.admin.base}/team-members`,
    tabs: [
      {
        name: 'Project Members',
        Content: ProjectMembersTab,
        url: `${PARTNERS.projects.admin.base}/team-members`,
      },
      {
        name: '> Invite',
        Content: ProjectInviteProjectMemberTab,
        visibleURLCondition: `${PARTNERS.projects.admin.base}/team-members/invite`,
        url: `${PARTNERS.projects.admin.base}/team-members/invite`,
      },
    ],
  },
  {
    name: 'Packages',
    url: `${PARTNERS.projects.admin.base}/packages`,
    tabs: [
      {
        name: 'Packages',
        Content: ProjectPackagesTab,
        url: `${PARTNERS.projects.admin.section}/packages`,
      },
      {
        name: '> Add Package',
        Content: ProjectAddPackageTab,
        visibleURLCondition: `${PARTNERS.projects.admin.base}/packages/add-package`,
        url: `${PARTNERS.projects.admin.base}/packages/add-package`,
      },
    ],
  },
  {
    name: 'Security',
    url: `${PARTNERS.projects.admin.base}/security`,
    tabs: [
      {
        name: 'Security',
        Content: SecurityPage,
        url: `${PARTNERS.projects.admin.base}/security`,
      },
    ],
  },
  {
    name: 'Notifications',
    url: `${PARTNERS.projects.admin.base}/notifications`,
    tabs: [
      {
        name: 'Notifications',
        Content: Notifications,
        url: `${PARTNERS.projects.admin.base}/notifications`,
      },
    ],
  },
]

export default function PartnerRoutes({ logged, user }) {
  return (
    <Route
      path={PARTNERS.base}
      render={() => (
        <MainLayout noPadding logged={logged} user={user}>
          <Switch>
            <Route
              path={PARTNERS.new}
              exact
              render={() => (
                <TabsLayout
                  Header={PartnerHeader}
                  menuItems={newPartnerAdminMenuItems}
                />
              )}
            />

            <Route exact path={PARTNERS.details} component={ProjectsPage} />

            {/* Projects Admin */}
            <Route
              path={PARTNERS.projects.new}
              exact
              render={() => (
                <TabsLayout
                  Header={ProjectHeader}
                  menuItems={newProjectAdminMenuItems}
                />
              )}
            />

            <Route
              path={[
                PARTNERS.projects.admin.base,
                PARTNERS.projects.admin.section,
                PARTNERS.projects.admin.subSection,
              ]}
              exact
              render={() => (
                <TabsLayout
                  Header={ProjectHeader}
                  menuItems={projectAdminMenuItems}
                />
              )}
            />

            {/* Partner Admin */}
            <Route
              path={[
                PARTNERS.admin.base,
                PARTNERS.admin.section,
                PARTNERS.admin.subSection,
                PARTNERS.admin.editTeamMember,
              ]}
              exact
              render={() => (
                <TabsLayout
                  Header={PartnerHeader}
                  menuItems={partnerAdminMenuItems}
                />
              )}
            />

            <Route component={Error404Page} />
          </Switch>
        </MainLayout>
      )}
    />
  )
}
