import React from 'react'

import style from './style.module.css'

const CopyIcon = ({
  className = '',
  color = '#d76acd',
  onClick,
  testId = 'icon-align-left',
}) => {
  return (
    <svg
      data-test={testId}
      width="16"
      height="16"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : style.icon}
      onClick={onClick}
    >
      <path
        d="M15.9225806,12.9230769 L15.9225806,14.9230769 L0.0516129032,14.9230769 L0.0516129032,12.9230769 L15.9225806,12.9230769 Z M9.34193548,8.61538462 L9.34193548,10.6153846 L0.0516129032,10.6153846 L0.0516129032,8.61538462 L9.34193548,8.61538462 Z M15.9225806,4.30769231 L15.9225806,6.30769231 L0.0516129032,6.30769231 L0.0516129032,4.30769231 L15.9225806,4.30769231 Z M9.34193548,0 L9.34193548,2 L0.0516129032,2 L0.0516129032,0 L9.34193548,0 Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  )
}

export default CopyIcon
