export function updateURLParameter(parameter, value) {
  const url = new URL(window.location)
  const urlParams = new URLSearchParams(url.search)

  urlParams.delete(parameter)

  if (value) {
    urlParams.append(parameter, value)
  }

  return urlParams.toString()
}
