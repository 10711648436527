import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Form, InputField, SubmitButton } from '@labsavvyapp/ui-components'

import style from './VerifyUserEmail.module.css'
import { ExistUserEmail } from '../../../../../graphql/user/queries.js'

export default function VerifyUserEmail({ onComplete }) {
  const { refetch } = useQuery(ExistUserEmail, { skip: true })

  async function handleSubmit({ email }) {
    const { data } = await refetch({
      email,
    })
    onComplete({ id: data.existUserEmail, email })
  }

  return (
    <Form onSubmit={handleSubmit} className={style.container}>
      <InputField placeholder="Email address" name="email" />
      <SubmitButton className={style.searchButton}>Search</SubmitButton>
    </Form>
  )
}
