import React from 'react'
import { Switch, Route } from 'react-router'

import { SIGN } from '../config/routes'
import AuthLayout from '../components/AuthLayout/AuthLayout'
import SignInPage from '../pages/Auth/SignIn/SignInPage'
import SignOutPage from '../pages/Auth/SignOut/SignOutPage'
import RecoverPassword from '../pages/Auth/RecoverPassword/RecoverPassword'
import RecoverConfirmToken from '../pages/Auth/RecoverPassword/RecoverConfirmToken'
import RecoverNewPassword from '../pages/Auth/RecoverPassword/RecoverNewPassword'
import RecoverFinish from '../pages/Auth/RecoverPassword/RecoverFinish'

export default function AuthenticationRoutes({ path }) {
  return (
    <Route
      path={path}
      render={() => (
        <AuthLayout>
          <Switch>
            <Route exact path={SIGN.in} component={SignInPage} />
            <Route exact path={SIGN.out} component={SignOutPage} />
            <Route exact path={SIGN.recover} component={RecoverPassword} />
            <Route
              exact
              path={SIGN.recoverConfirmToken}
              component={RecoverConfirmToken}
            />
            <Route
              exact
              path={SIGN.recoverNewPassword}
              component={RecoverNewPassword}
            />
            <Route exact path={SIGN.recoverFinish} component={RecoverFinish} />
          </Switch>
        </AuthLayout>
      )}
    />
  )
}
