import React from 'react'
import classnames from 'classnames'

import style from './TestListItem.module.css'
import sharedStyle from './TestGroupSelectionPanel.module.css'

export const TestListItem = ({ item, selected, disabled, onClick }) => {
  const test = item.tests[0]

  return (
    <div
      className={classnames(sharedStyle.listItem, {
        [sharedStyle.selected]: selected,
        [sharedStyle.disabled]: disabled,
      })}
      onClick={() => !item.selected && onClick(test)}
      data-test="test-list-item"
    >
      <span className={style.resultCode}>{test.result.code}</span>
      <span className={style.resultName}>{test.result.name}</span>
      <span className={style.orderCode}>{test.order.code}</span>
      <span className={style.orderName}>{test.order.name}</span>
    </div>
  )
}
