import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  Form,
  SquareImageUploadField,
  InputField,
  RTEField,
  SubmitButton,
  Button,
  validate,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import { useIntl } from 'react-intl'
import style from './PackageCategoriesFormPage.module.css'
import sharedStyle from '../../../styles/shared.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { PACKAGE_CATEGORIES } from '../../../config/routes'
import { UploadImage } from '../../../graphql/files/mutations.js'
import { GetPackageCategory } from '../../../graphql/package-categories/queries.js'
import {
  CreatePackageCategory,
  UpdatePackageCategory,
  DeletePackageCategory,
} from '../../../graphql/package-categories/mutations.js'

export default function PackageCategoriesFormPage() {
  const { push } = useHistory()
  const { packageCategoryId } = useParams()
  const { formatMessage } = useIntl()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  // Mutations
  // Workaround while a real fix isn't ready
  // https://labsavvy.atlassian.net/browse/L2-724
  const [upload, { loading: loadingImage }] = useMutation(UploadImage)
  const [createPackageCategory] = useMutation(CreatePackageCategory)
  const [updatePackageCategory] = useMutation(UpdatePackageCategory, {
    refetchQueries: [
      {
        query: GetPackageCategory,
        variables: { packageCategoryId },
      },
    ],
  })
  const [deletePackageCategory] = useMutation(DeletePackageCategory, {
    refetchQueries: [
      {
        query: GetPackageCategory,
        variables: { packageCategoryId },
      },
    ],
  })

  // Queries
  const { data } = useQuery(GetPackageCategory, {
    skip: !packageCategoryId,
    variables: { packageCategoryId },
  })

  const isEditing = Boolean(packageCategoryId)
  const pageTitle = `${isEditing ? 'Edit' : 'Add'} Package Category`

  const icon =
    data &&
    data.getPackageCategory &&
    data.getPackageCategory.icon &&
    data.getPackageCategory.icon.url

  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the package category, please wait...",
    savedMessage: 'The package category has been saved.',
    callback: async (variables) => {
      if (isEditing) {
        await updatePackageCategory({
          variables,
        })
      } else {
        await createPackageCategory({
          variables,
        })
      }
    },
    onSuccess: () => push(PACKAGE_CATEGORIES.base),
  })
  // Delete Modals
  const [deleteModals, { showConfirmationModal: showDeleteConfirmationModal }] =
    useSavingModals({
      savingMessage: "We're deleting the package category, please wait...",
      savedMessage: 'The package category has been deleted.',
      confirmationMessage:
        'Are you sure you want to delete this package category?',
      onConfirm: async () => {
        await deletePackageCategory({
          variables: {
            packageCategoryId,
          },
        })
      },
      onSuccess: () => push(PACKAGE_CATEGORIES.base),
    })

  const handleSubmit = async (formData) => {
    const packageCategory = {
      name: formData.name,
      subtitle: formData.subtitle,
      summary: formData.summary,
      details: formData.details,
      icon_id: formData.icon_id,
    }
    if (isEditing) {
      showModals({
        packageCategoryId: formData._id,
        packageCategory,
      })
    } else {
      showModals({
        packageCategory,
      })
    }
  }

  return (
    <MainContainer>
      <Form
        initialFormData={data && data.getPackageCategory}
        onSubmit={handleSubmit}
        intl={{ formatMessage }}
      >
        <header className={style.header}>
          <h1 className={sharedStyle.pageTitle}>{pageTitle}</h1>

          <div className={style.buttons}>
            <Button
              className={style.cancelButton}
              variant={Button.variant.secondary}
              onClick={() => push(PACKAGE_CATEGORIES.base)}
              data-test="button-cancel"
            >
              Cancel
            </Button>
            <SubmitButton
              primary
              className={style.submitButton}
              disabled={loadingImage}
            >
              {isEditing ? 'Update' : 'Save'}
            </SubmitButton>
          </div>
        </header>

        <Grid className={style.packageCategoriesGrid}>
          <Grid.Row>
            <Grid.Column>
              <InputField
                name="name"
                label="Category Name"
                validate={validate.notEmpty()}
                disabled={loadingImage}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column tablet={6} computer={5}>
              <Grid.Row>
                <Grid.Column className={style.inputColumn}>
                  <InputField
                    name="subtitle"
                    label="Subtitle (optional)"
                    disabled={loadingImage}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className={style.inputColumn}>
                  <RTEField
                    name="summary"
                    label="Summary"
                    showWordCount
                    className={style.summary}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className={style.iconColumn}>
                  <SquareImageUploadField
                    image={icon}
                    name="icon_id"
                    label="Icon"
                    showLoader={loadingImage}
                    uploadFn={uploadFile}
                  />
                </Grid.Column>
              </Grid.Row>
              {isEditing && (
                <Grid.Row>
                  <Grid.Column className={style.iconColumn}>
                    <Button
                      variant={Button.variant.basic}
                      onClick={showDeleteConfirmationModal}
                      className={style.deleteButton}
                      data-test="button-delete-category"
                    >
                      Delete Category
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid.Column>
            <Grid.Column tablet={10} computer={11}>
              <Grid.Row>
                <Grid.Column className={style.inputColumn}>
                  <RTEField
                    name="details"
                    label="Details"
                    showWordCount
                    className={style.details}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
      {deleteModals}

      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
    </MainContainer>
  )
}
