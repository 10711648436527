import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'
import { useIntl } from 'react-intl'

import {
  Form,
  SubmitButton,
  LogoImageUploadField,
  ColorField,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'

import { UploadImage } from '../../../../graphql/files/mutations.js'
import { GetPartnerBranding } from '../../../../graphql/partner/queries.js'
import { UpdatePartner } from '../../../../graphql/partner/mutations.js'
import sharedStyles from '../../../../styles/shared.module.css'
import style from './BrandingTab.module.css'

export default function BrandingTab() {
  const { formatMessage } = useIntl()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { partnerId } = useParams()
  const [updatePartner] = useMutation(UpdatePartner, {
    refetchQueries: [
      {
        query: GetPartnerBranding,
        variables: {
          id: partnerId,
        },
      },
    ],
  })

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the partner, please wait...",
    savedMessage: 'The partner has been saved.',
    callback: async ({ logo_id, branding }) => {
      await updatePartner({
        variables: {
          id: partnerId,
          data: {
            logo_id,
            branding,
          },
        },
      })
    },
  })

  const [upload, uploadData] = useMutation(UploadImage)

  const { data: partnerData } = useQuery(GetPartnerBranding, {
    variables: {
      id: partnerId,
    },
  })

  const partnerPhoto =
    partnerData &&
    partnerData.getPartner &&
    partnerData.getPartner.logo &&
    partnerData.getPartner.logo.url

  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  return (
    <>
      {/* Modals */}
      {modals}

      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />

      {/* Form */}
      <Form
        initialFormData={partnerData && partnerData.getPartner}
        onSubmit={showModals}
        intl={{ formatMessage }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <LogoImageUploadField
                image={partnerPhoto}
                label="Logo"
                name="logo_id"
                showLoader={uploadData.loading}
                uploadFn={uploadFile}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <hr />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <div className={style.colorFields}>
                <ColorField
                  className={style.colorField}
                  label="Sidebar Color"
                  name="branding.sidebar_color"
                />
                <ColorField
                  className={style.colorField}
                  label="Icon selected"
                  name="branding.icon_selected"
                />
                <ColorField
                  className={style.colorField}
                  label="Button Background"
                  name="branding.button_background"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <div className={style.colorFields}>
                <ColorField
                  className={style.colorField}
                  label="Button Text"
                  name="branding.button_text"
                />
                <ColorField
                  className={style.colorField}
                  label="Text Links"
                  name="branding.text_link"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
