import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'

import {
  Form,
  ProfileImageUploadField,
  InputField,
  SelectField,
  SubmitButton,
  Button,
  validate,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'

import style from './TeamMemberForm.module.css'
import sharedStyle from '../../../styles/shared.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { ROLES_OPTIONS } from './constants'
import { TEAM_MEMBERS } from '../../../config/routes'
import { UploadImage } from '../../../graphql/files/mutations.js'
import { GetAdminTeamMember } from '../../../graphql/team-members/queries.js'
import {
  InviteAdminTeamMember,
  UpdateAdminTeamMember,
} from '../../../graphql/team-members/mutations.js'

export default function TeamMemberForm() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { id } = useParams()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // Mutations
  const [upload] = useMutation(UploadImage)
  const [inviteAdminTeamMember] = useMutation(InviteAdminTeamMember)
  const [updateAdminTeamMember] = useMutation(UpdateAdminTeamMember, {
    refetchQueries: [
      {
        query: GetAdminTeamMember,
        variables: { id },
      },
    ],
  })

  // Queries
  const { data: userData } = useQuery(GetAdminTeamMember, {
    skip: !id,
    variables: { id, platform: process.env.REACT_APP_PLATFORM_ADMIN },
  })

  const isEditing = Boolean(id)
  const pageTitle = `${isEditing ? 'Manage' : 'Invite'} Team Member`

  const profilePhoto =
    userData &&
    userData.getAdminTeamMember &&
    userData.getAdminTeamMember.profile_photo &&
    userData.getAdminTeamMember.profile_photo.url

  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the team member, please wait...",
    savedMessage: 'The team member has been saved.',
    callback: async (variables) => {
      if (isEditing) {
        await updateAdminTeamMember({
          variables,
        })
      } else {
        await inviteAdminTeamMember({
          variables,
        })
      }
    },
  })

  const handleSubmit = async (formData) => {
    const user = {
      name: {
        first: formData.name.first,
        last: formData.name.last,
      },
      role: {
        role: formData.role.key,
        custom_perms: {
          result_index_categories:
            (formData.role.custom_perms &&
              formData.role.custom_perms.result_index_categories) ||
            [],
          article_types:
            (formData.role.custom_perms &&
              formData.role.custom_perms.article_types) ||
            [],
        },
      },
      profile_photo_id: formData.profile_photo_id,
      email: formData.email,
    }
    if (isEditing) {
      showModals({
        id: formData._id,
        user,
      })
    } else {
      showModals({
        user,
      })
    }
  }

  return (
    <MainContainer>
      <header className={style.header}>
        <h1 className={sharedStyle.pageTitle}>{pageTitle}</h1>
      </header>

      <Form
        className={style.form}
        initialFormData={userData && userData.getAdminTeamMember}
        onSubmit={handleSubmit}
        intl={{ formatMessage }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column tablet={3} computer={2}>
              <ProfileImageUploadField
                image={profilePhoto}
                name="profile_photo_id"
                label="Profile Photo"
                uploadFn={uploadFile}
              />
            </Grid.Column>
            <Grid.Column tablet={6} computer={5}>
              <Grid.Row>
                <Grid.Column>
                  <InputField
                    name="name.first"
                    label="First Name"
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className={style.inputColumn}>
                  <InputField
                    name="name.last"
                    label="Last Name"
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className={style.inputColumn}>
                  <InputField
                    name="email"
                    label="Email Address"
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className={style.buttons}>
                    <SubmitButton primary className={style.submitButton}>
                      {isEditing ? 'Update' : 'Send Invite'}
                    </SubmitButton>
                    <Button
                      className={style.cancelButton}
                      onClick={() => push(TEAM_MEMBERS.base)}
                      variant={Button.variant.secondary}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column tablet={6} computer={5}>
              <Grid.Row>
                <Grid.Column>
                  <SelectField
                    name="role.key"
                    label="Role"
                    className={style.roleDropDown}
                    options={Object.keys(ROLES_OPTIONS).map((role) => ({
                      value: role,
                      text: ROLES_OPTIONS[role],
                    }))}
                    validate={validate.notEmpty()}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
    </MainContainer>
  )
}
