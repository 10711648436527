import React from 'react'

import classnames from 'classnames'
import get from 'lodash.get'
import { ListRow, NameInitials, ProfilePhoto } from '@labsavvyapp/ui-components'

import deleteIcon from '../../../../../components/Icons/delete-icon.svg'
import style from './TeamMembersList.module.css'

// FIXME: We have some checks, like to check if the first name exists.
// These need to be removed once the DB is fixed.
export default function TeamMembersListRow({ items, onDeleteClick }) {
  return items.map((item) => (
    <ListRow key={item.id} className={classnames(style.listRow, style.row)}>
      <div className={classnames(style.column, style.imageNameColumn)}>
        {item.profilePhoto ? (
          <ProfilePhoto
            className={style.memberImage}
            size={35}
            url={get(item, 'profilePhoto.url')}
          />
        ) : item.name && item.name.first && item.name.last ? (
          <NameInitials
            firstName={item.name.first}
            lastName={item.name.last}
            size={34}
            className={style.memberImage}
          />
        ) : (
          ''
        )}

        {get(item, 'name.display', '')}
      </div>
      <div className={classnames(style.column, style.emailColumn)}>
        {item.email}
      </div>
      <div className={classnames(style.column, style.roleColumn)}>
        {get(item, 'role.name', '-')}
      </div>
      <div className={classnames(style.column, style.statusColumn)}>
        {item.status}
      </div>
      <div className={style.deleteIconColumn}>
        <img
          alt="delete-team-member"
          src={deleteIcon}
          onClick={() => onDeleteClick(item.id)}
        />
      </div>
    </ListRow>
  ))
}
