import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useIntl } from 'react-intl'

import {
  Button,
  Form,
  FormContext,
  InputField,
  Modal,
  SubmitButton,
  ProfileImageUploadField,
  validate,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'

import MainContainer from '../../../components/MainContainer/MainContainer'
import { PROFILE, HOME } from '../../../config/routes'
import { UploadImage } from '../../../graphql/files/mutations.js'
import { UpdateMe } from '../../../graphql/user/mutations.js'
import { GetMe, GetProfile } from '../../../graphql/user/queries.js'
import style from './ProfilePage.module.css'

export default function ProfilePage() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // User data
  const { data: profileData } = useQuery(GetProfile)
  const getMe = profileData && profileData.getMe
  const initialFormData = getMe

  const profilePhoto =
    profileData &&
    profileData.getMe &&
    profileData.getMe.profile_photo &&
    profileData.getMe.profile_photo.url

  // Update Profile Photo
  const [upload] = useMutation(UploadImage)
  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  // Update user information
  const [updateMe] = useMutation(UpdateMe, {
    refetchQueries: [
      {
        query: GetMe,
      },
    ],
  })
  const handleUpdate = (formData) => {
    const { name, emails, profile_photo_id } = formData

    showModals({
      data: {
        name: {
          first: name.first,
          last: name.last,
        },
        emails: [
          {
            address: emails[0].address,
          },
        ],
        profile_photo_id,
      },
    })
  }

  const handleCancel = (resetFields) => {
    resetFields()
    push(HOME)
  }

  // Progress modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving your profile information, please wait...",
    savedMessage: 'Profile information saved.',
    errorMessage: 'Error saving user profile',
    callback: ({ data }) =>
      updateMe({
        variables: {
          data,
        },
      }),
  })

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Modal
        open={modalOpen}
        onCloseClick={() => setModalOpen(false)}
        testId="modal-reset-password"
        title="Reset Password"
      >
        <div className={style.modal}>
          <div>Are you sure you want to reset your password?</div>
          <div className={style.modalButtons}>
            <Button
              data-test="modal-button-yes"
              onClick={() => {
                push(PROFILE.accountVerification)
              }}
            >
              Yes
            </Button>
            <Button
              className={style.cancelButton}
              data-test="modal-button-cancel"
              onClick={() => setModalOpen(false)}
              variant={Button.variant.secondary}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <MainContainer>
        <header className={style.header}>
          <h1 className="page-title">Profile</h1>
        </header>

        <Form
          className={style.contentContainer}
          onSubmit={handleUpdate}
          initialFormData={initialFormData}
          intl={{ formatMessage }}
        >
          <FormContext.Consumer>
            {({ resetFields }) => (
              <>
                <div className={style.columnProfile}>
                  <ProfileImageUploadField
                    image={profilePhoto}
                    label="Profile Photo"
                    name="profile_photo_id"
                    uploadFn={uploadFile}
                  />
                  <div
                    className={style.resetPassword}
                    data-test="link-reset-password"
                    onClick={() => setModalOpen(true)}
                  >
                    Reset Password
                  </div>
                </div>
                <div className={style.columnFields}>
                  <InputField
                    className={style.input}
                    label="First Name"
                    name="name.first"
                    validate={validate.notEmpty()}
                  />
                  <InputField
                    className={style.input}
                    label="Last Name"
                    name="name.last"
                    validate={validate.notEmpty()}
                  />
                  <InputField
                    className={style.input}
                    label="Email Address"
                    name="emails.0.address"
                    validate={validate.combine(
                      validate.notEmpty(),
                      validate.isEmail(),
                    )}
                  />
                  <div className={style.buttonsContainer}>
                    <SubmitButton>SAVE CHANGES</SubmitButton>
                    <Button
                      className={style.cancelButton}
                      onClick={() => handleCancel(resetFields)}
                      data-test="button-cancel"
                      variant={Button.variant.secondary}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            )}
          </FormContext.Consumer>
        </Form>
      </MainContainer>

      {modals}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
    </>
  )
}
