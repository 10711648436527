import React from 'react'

import { Grid } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams, useHistory, generatePath } from 'react-router'
import { camelizeKeys } from 'humps'
import {
  Form,
  SelectField,
  SubmitButton,
  validate,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { PARTNERS } from '../../../../config/routes'
import sharedStyles from '../../../../styles/shared.module.css'
import { ListPartnerProjectTeamMembers } from '../../../../graphql/partner/queries.js'
import { InvitePartnerProjectTeamMember } from '../../../../graphql/partner/mutations.js'

export default function InviteProjectMemberTab() {
  const { partnerId, projectId } = useParams()
  const { push } = useHistory()

  const [invitePartnerProjectTeamMember] = useMutation(
    InvitePartnerProjectTeamMember,
  )

  // Fetch partner team member in order to populate the dropdown
  const { data } = useQuery(ListPartnerProjectTeamMembers, {
    variables: { partnerId, projectId },
  })
  const teamMembersData = camelizeKeys(data)
  const teamMemberOptions = teamMembersData
    ? teamMembersData.listPartnerProjectTeamMembers.partnerTeamMembers.map(
        ({ id, name }) => ({
          key: id,
          value: id,
          text: name.display,
        }),
      )
    : []

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're sending your invite, please wait...",
    savedMessage: 'Invitation sent.',
    errorMessage: 'Error sending invite.',
    callback: ({ userId }) => {
      invitePartnerProjectTeamMember({
        variables: { partnerId, projectId, userId },
        refetchQueries: [
          {
            query: ListPartnerProjectTeamMembers,
            variables: {
              partnerId,
              projectId,
            },
          },
        ],
      })
    },
    onSuccess: () =>
      push(
        generatePath(PARTNERS.projects.admin.section, {
          partnerId,
          projectId,
          section: 'team-members',
        }),
      ),
  })

  async function handleFormSubmit({ userId }) {
    showModals({ userId })
  }

  return (
    <>
      <Form onSubmit={handleFormSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="userId"
                label="Name"
                options={teamMemberOptions}
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Send Invite
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
