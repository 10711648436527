import React from 'react'
import classnames from 'classnames'

import style from './MainContainer.module.css'

export default function MainContainer({
  children,
  className = '',
  padded = false,
}) {
  return (
    <main
      className={classnames(style.container, className, {
        [style.containerPadding]: padded,
      })}
    >
      {children}
    </main>
  )
}
