import React from 'react'
import { Switch, Route } from 'react-router'

import { MAIN_PRICING } from '../config/routes'
import MainPricingPage from '../pages/MainPricing/MainPricing/MainPricingPage'
import MainLayout from '../components/MainLayout/MainLayout'

export default function MainPricingRoutes({ logged, user }) {
  return (
    <MainLayout noPadding logged={logged} user={user}>
      <Switch>
        <Route exact path={MAIN_PRICING.base} component={MainPricingPage} />
      </Switch>
    </MainLayout>
  )
}
