import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Search, Button } from '@labsavvyapp/ui-components'

import style from './CategoriesPage.module.css'
import CategoryForm from './CategoryForm'
import CategoriesList from './CategoriesList'
import { updateURLParameter } from '../../utils/urls'
import { ListTestReferenceCategories } from '../../graphql/categories/queries.js'

function getQueryVariables(search) {
  const variables = {
    limit: 50,
    filter: {},
  }

  if (search) {
    variables.filter.name = search
  }

  return variables
}

export default function CategoriesPage() {
  const { push } = useHistory()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const searchParameter = query.get('search')

  const [selectedCategory, setSelectedCategory] = useState(null)
  const [addNew, setAddNew] = useState(false)
  const [search, setSearch] = useState(searchParameter || '')

  // Fetch category list
  const { data, networkStatus, refetch, fetchMore } = useQuery(
    ListTestReferenceCategories,
    {
      variables: { ...getQueryVariables(search) },
      notifyOnNetworkStatusChange: true,
    },
  )
  const categoriesData = data && camelizeKeys(data)

  useEffect(() => {
    refetch({
      ...getQueryVariables(search),
      page: 1,
    })
  }, [search, refetch])

  function handleAddNewClick() {
    setAddNew(true)
    setSelectedCategory(null)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`categories?${urlParams}`)
    setSearch(value)
  }

  function handleCategorySelection(category) {
    setAddNew(false)
    setSelectedCategory(category)
  }

  return (
    <>
      <Helmet>
        <title>Categories</title>
      </Helmet>
      <div className={style.container}>
        <div className={style.searchContainer}>
          <Search
            className={style.searchInput}
            placeholder="Search Categories"
            showNoResults={false}
            searchText={search}
            onSearchChange={handleSearchChange}
          />

          <Button
            className={style.newButton}
            data-test="add-new-category"
            onClick={handleAddNewClick}
          >
            Add New
          </Button>
        </div>

        <div className={style.sectionsContainer}>
          <section className={style.section}>
            <CategoriesList
              data={categoriesData}
              loading={networkStatus === 1 || networkStatus === 3}
              fetchMore={fetchMore}
              selected={selectedCategory}
              onClick={handleCategorySelection}
            />
          </section>
          <section className={style.section}>
            {(addNew || selectedCategory) && (
              <CategoryForm
                data={selectedCategory}
                onDelete={() => handleCategorySelection(null, true)}
                onUpdate={(category) => handleCategorySelection(category, true)}
              />
            )}
          </section>
        </div>
      </div>
    </>
  )
}
