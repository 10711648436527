import gql from 'graphql-tag'

export const GetAdminTeamMember = gql`
  query GetAdminTeamMember($id: MongoID!) {
    getAdminTeamMember(id: $id) {
      _id
      profile_photo {
        url
      }
      role {
        key
        custom_perms {
          result_index_categories
          article_types
        }
      }
      name {
        first
        last
        display
      }
      email
    }
  }
`

export const ListAdminTeamMembers = gql`
  query ListAdminTeamMembers(
    $limit: PositiveInt
    $filter: TeamMemberFilter
    $sort: TeamMemberSort = { name: { first: asc } }
  ) {
    listAdminTeamMembers(limit: $limit, filter: $filter, sort: $sort) {
      admin_team_members {
        _id
        profile_photo {
          url
        }
        name {
          first
          last
          display
        }
        email
        role {
          name
        }
        joined
      }
      page
      pages
      total
    }
  }
`
