import gql from 'graphql-tag'

export const Login = gql`
  mutation Login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        _id
      }
      tokens {
        access
      }
    }
  }
`

export const UpdateMe = gql`
  mutation UpdateMe($data: UserInput!) {
    updateMe(data: $data) {
      _id
    }
  }
`

export const PreRecoverEmail = gql`
  mutation PreRecoverEmail($email: EmailAddress!) {
    preRecoverEmail(media: email, email: $email)
  }
`

export const ProofRecoveryEmail = gql`
  mutation ProofRecoveryEmail($email: EmailAddress!, $token: String!) {
    proofRecoveryEmail(email: $email, token: $token)
  }
`

export const RecoverEmail = gql`
  mutation RecoverEmail(
    $proof: String!
    $email: EmailAddress!
    $newPassword: String!
  ) {
    recoverEmail(proof: $proof, email: $email, newPassword: $newPassword)
  }
`
