import React, { useState, useEffect } from 'react'

import { Grid } from 'semantic-ui-react'
import { useHistory, useParams, generatePath } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import delay from 'await-delay'
import {
  Form,
  InputField,
  ProfileImageUploadField,
  SubmitButton,
  SelectField,
  Button,
  validate,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import get from 'lodash.get'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { useIntl } from 'react-intl'
import { PARTNERS } from '../../../../config/routes'
import { GetUser } from '../../../../graphql/user/queries.js'
import {
  GetPartnerTeamMember,
  ListPlatformRoles,
} from '../../../../graphql/partner/queries.js'
import {
  DeletePartnerTeamMember,
  EditPartnerTeamMember,
  InvitePartnerTeamMember,
} from '../../../../graphql/partner/mutations.js'
import { UploadImage } from '../../../../graphql/files/mutations.js'
import sharedStyles from '../../../../styles/shared.module.css'
import VerifyUserEmail from './VerifyUserEmail/VerifyUserEmail'
import style from './FormTab.module.css'

export default function FormTab() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { partnerId, teamMemberId } = useParams()
  const isEditing = Boolean(teamMemberId)

  const [userToInvite, setUserToInvite] = useState()
  const [formData, setFormData] = useState()
  const [showError, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // Fetch current platform roles
  const { data: listPlatformRoles } = useQuery(ListPlatformRoles, {
    variables: {
      platform: process.env.REACT_APP_PLATFORM_WEBAPP,
    },
  })

  // Modals
  // Confirmation modal is used for the delete partner teamMember action.
  // showSavingModal, showSavedModal and showErrorModal are used for the
  // invite partner team member.
  const [deletePartnerTeamMember] = useMutation(DeletePartnerTeamMember)

  const [
    modals,
    {
      showSavingModal,
      showSavedModal,
      showErrorModal,
      showConfirmationModal: showDeleteConfirmationModal,
    },
  ] = useSavingModals({
    savingMessage: "We're saving the team member, please wait...",
    savedMessage: 'The team member has been saved.',
    confirmationMessage: 'Are you sure you want to delete this team member?',
    onConfirm: () => {
      deletePartnerTeamMember({
        variables: {
          partner_id: partnerId,
          team_member_id: teamMemberId,
        },
      })
    },
    onSuccess: () =>
      push(
        generatePath(PARTNERS.admin.section, {
          partnerId,
          section: 'team-members',
        }),
      ),
  })

  // Upload photo
  const [upload] = useMutation(UploadImage)
  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('EError uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  // Invite/ edit team member
  const [invitePartnerTeamMember] = useMutation(InvitePartnerTeamMember)
  const [editPartnerTeamMember] = useMutation(EditPartnerTeamMember)

  const decamelize = (data) =>
    decamelizeKeys(data, {
      process: function (key, convert, options) {
        return /^typename$/.test(key) ? '__typename' : convert(key, options)
      },
    })

  const handleSubmit = async (data) => {
    const {
      name,
      academicTitle,
      jobTitle,
      memberBio,
      email,
      role,
      profilePhotoId,
    } = data
    const parsedFormData = {
      name: {
        first: name.first,
        last: name.last,
      },
      academicTitle,
      jobTitle,
      memberBio,
      email,
      role,
      profilePhotoId,
    }

    showSavingModal(true)
    try {
      if (isEditing) {
        await editPartnerTeamMember({
          variables: {
            partner_id: partnerId,
            team_member_id: teamMemberId,
            data: decamelize(parsedFormData),
          },
          refetchQueries: 'GetPartnerTeamMember',
        })
      } else {
        await invitePartnerTeamMember({
          variables: {
            id: partnerId,
            data: decamelize(parsedFormData),
          },
          refetchQueries: 'GetPartnerTeamMember',
        })
      }
      await delay(1000)

      showSavingModal(false)
      showSavedModal(true)
      await delay(1000)
      push(
        generatePath(PARTNERS.admin.section, {
          partnerId,
          section: 'team-members',
        }),
      )
    } catch {
      showSavingModal(false)
      showErrorModal(true)
    }
  }

  // Fetch user
  const { refetch: refetchUser } = useQuery(GetUser, {
    skip: true,
  })

  // Fetch partner team member
  const { data } = useQuery(GetPartnerTeamMember, {
    variables: {
      team_member_id: teamMemberId,
    },
    skip: !teamMemberId,
  })
  const teamMemberPhoto = get(formData, 'profilePhoto.url')

  function parseInputFormData(formData) {
    const {
      name,
      email,
      profilePhoto,
      role,
      academicTitle,
      jobTitle,
      memberBio,
    } = formData

    return {
      name,
      email,
      role: get(role, 'key'),
      profilePhoto: {
        id: get(profilePhoto, 'id'),
        url: get(profilePhoto, 'url'),
      },
      academicTitle,
      jobTitle,
      memberBio,
    }
  }

  useEffect(() => {
    if (data) {
      const partnerTeamMemberData = data && camelizeKeys(data)
      setFormData(
        parseInputFormData(partnerTeamMemberData.getPartnerTeamMember),
      )
    }
  }, [data])

  async function handleVerifyComplete({ id, email }) {
    setUserToInvite({ id, email })

    if (id) {
      // Fetch the current user information to pre-fill the form
      const { data: partnerTeamMemberData } = await refetchUser({
        id,
      })
      partnerTeamMemberData &&
        setFormData(
          parseInputFormData({
            ...partnerTeamMemberData.getUser,
            email: partnerTeamMemberData.getUser.emails[0].address,
          }),
        )
    } else {
      setFormData({ email })
    }
  }

  // Invite user: Search for the user's email, to check if it already exists
  if (!isEditing && !userToInvite) {
    return (
      <div className={style.formContainer}>
        <Grid>
          <Grid.Row>
            <Grid.Column widescreen={12}>
              <VerifyUserEmail onComplete={handleVerifyComplete} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  // Edit/ Invite user
  return (
    <>
      <div className={style.formContainer}>
        <Grid>
          <Grid.Row>
            <Grid.Column widescreen={12}>
              {!isEditing && !userToInvite.id && (
                <div className={style.infoMessage}>User not found.</div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Form
          initialFormData={formData}
          onSubmit={handleSubmit}
          intl={{ formatMessage }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column computer={3} widescreen={2}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <ProfileImageUploadField
                        image={teamMemberPhoto}
                        name="profilePhotoId"
                        label="Profile Photo"
                        uploadFn={uploadFile}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {isEditing && (
                    <Grid.Row>
                      <Grid.Column>
                        {/* FIXME: Temporarily disabled
                      <Button style="basic" className={sharedStyles.linkButton}>
                        Suspend User
                      </Button>
                      <br /> */}
                        <Button
                          data-test="button-delete-user"
                          className={sharedStyles.linkButton}
                          onClick={showDeleteConfirmationModal}
                          variant={Button.variant.basic}
                        >
                          Delete User
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
              <Grid.Column computer={9} widescreen={10}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField
                        name="name.first"
                        label="First Name"
                        validate={validate.notEmpty()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField
                        name="name.last"
                        label="Last Name"
                        validate={validate.notEmpty()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField name="academicTitle" label="Academic Title" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField name="jobTitle" label="Job Title" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField name="memberBio" label="Member Bio" />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <InputField
                        name="email"
                        label="Email Address"
                        validate={validate.notEmpty()}
                        readOnly
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <SelectField
                        name="role"
                        label="Role"
                        options={
                          listPlatformRoles &&
                          listPlatformRoles.listPlatformRoles.map((role) => ({
                            value: role.key,
                            text: role.name,
                          }))
                        }
                        validate={validate.notEmpty()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        className={sharedStyles.marginRight20}
                        onClick={() =>
                          push(
                            PARTNERS.admin.section
                              .replace(':partnerId', partnerId)
                              .replace(':section', 'team-members'),
                          )
                        }
                        variant={Button.variant.secondary}
                      >
                        Cancel
                      </Button>
                      <SubmitButton className={sharedStyles.marginTop10}>
                        {isEditing ? 'Update' : 'Send Invite'}
                      </SubmitButton>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>

      {/* Modals */}
      {modals}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showError}
      />
    </>
  )
}
