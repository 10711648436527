import React from 'react'

import classnames from 'classnames'
import { ListRow } from '@labsavvyapp/ui-components'
import { useHistory } from 'react-router'

import sharedStyle from '../../../../styles/shared.module.css'
import { PACKAGES } from '../../../../config/routes'
import style from './PackagesListRow.module.css'
import formatterPrice from '../../../../utils/formatter-price'
export default function PackagesListRow({ items }) {
  const { push } = useHistory()

  return items.map((item) => (
    <ListRow
      data-test="package-list-row"
      key={item._id}
      className={classnames(sharedStyle.listRow, style.row)}
      onClick={() => push(`${PACKAGES.base}/${item._id}`)}
    >
      <div className={style.column}>{item.name}</div>
      <div className={style.column}>{formatterPrice(item.ls_price)}</div>
    </ListRow>
  ))
}
