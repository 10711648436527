import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'
import {
  Form,
  InputField,
  PhoneInputField,
  SubmitButton,
  SelectField,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import ZIPCodeField from '../../../../components/ZIPCodeField/ZIPCodeField'
import phoneTypes from '../../../../constants/phone-types'
import usaStates from '../../../../constants/usa-states'
import sharedStyles from '../../../../styles/shared.module.css'
import {
  GetPartner,
  GetPartnerContactInfoTab,
} from '../../../../graphql/partner/queries.js'
import { UpdatePartner } from '../../../../graphql/partner/mutations.js'

export default function ContactInfoTab() {
  const { partnerId } = useParams()

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the partner, please wait...",
    savedMessage: 'The partner has been saved.',
    callback: async (formData) => {
      await updatePartner({
        variables: {
          id: partnerId,
          data: formData,
        },
      })
    },
  })

  const { data } = useQuery(GetPartnerContactInfoTab, {
    variables: {
      id: partnerId,
    },
  })
  const partnerData = data && data.getPartner

  const [updatePartner] = useMutation(UpdatePartner, {
    refetchQueries: [
      {
        query: GetPartner,
        variables: { id: partnerId },
      },
    ],
  })

  return (
    <>
      {/* Modals */}
      {modals}

      {/* Form */}
      <Form initialFormData={partnerData} onSubmit={showModals}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="contact.name.first" label="First Name" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="contact.name.last" label="Last Name" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="contact.email" label="Email Address" />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="contact.company_url"
                label="Company URL (Web Address)"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={6} tablet={12}>
              <PhoneInputField
                name="contact.phone1.number"
                label="Phone Number 1"
                placeholder="( ____ ) _____ - _______"
                country="US"
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={4}>
              <SelectField
                name="contact.phone1.type"
                label="Type"
                options={phoneTypes}
              />
            </Grid.Column>
            <Grid.Column computer={6} tablet={12}>
              <PhoneInputField
                name="contact.phone2.number"
                label="Phone Number 2"
                placeholder="( ____ ) _____ - _______"
                country="US"
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={4}>
              <SelectField
                name="contact.phone2.type"
                label="Type"
                options={phoneTypes}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="contact.address.street"
                label="Street Address"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={16}>
              <InputField name="contact.address.city" label="City" />
            </Grid.Column>
            <Grid.Column computer={2} tablet={16}>
              <SelectField
                name="contact.address.state"
                label="State"
                options={usaStates}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={16}>
              <ZIPCodeField
                name="contact.address.zip"
                label="ZIP Code"
                addressFieldNames={[
                  'contact.address.street',
                  'contact.address.city',
                  'contact.address.state',
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
