import gql from 'graphql-tag'

export const GetPartnerPricing = gql`
  query (
    $page: PositiveInt
    $filter: PartnerPricingFilter!
    $sort: PartnerPricingSort
    $limit: PositiveInt
  ) {
    partnerPricing(
      input: { page: $page, filter: $filter, sort: $sort, limit: $limit }
    ) {
      data {
        id
        test_code
        test_name
        ls_price
        discount
        adjusted_price
        is_adjusted_price_overridden
        retail_price
        standard_retail_price
        provider_price
      }
      page
      pages
      total
    }
  }
`
