import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Search, Button, useSavingModals } from '@labsavvyapp/ui-components'

import style from './LabsTab.module.css'
import { SORT_BY_OPTIONS } from './constants'
import LabsList from './LabsList/LabsList'
import { updateURLParameter } from '../../../../utils/urls'
import { PARTNERS } from '../../../../config/routes'

//Queries and Mutations
import { ListPartnerProjectProviders } from '../../../../graphql/labs/queries.js'
import { DeletePartnerProjectProvider } from '../../../../graphql/labs/mutations.js'
import { GetPartnerProjectSettings } from '../../../../graphql/partner/queries.js'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = SORT_BY_OPTIONS.nameZA.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function LabsTab() {
  const { push } = useHistory()
  const { partnerId, projectId, section } = useParams()

  const query = new URLSearchParams(window.location.search)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // Get Default Lab/Provider
  const {
    data: projectSettingsData,
    error,
    refetch: refetchProjectSettings,
  } = useQuery(GetPartnerProjectSettings, {
    variables: { partnerId, projectId },
    notifyOnNetworkStatusChange: true,
  })

  // Fetch packages list
  const { data, networkStatus, fetchMore, refetch } = useQuery(
    ListPartnerProjectProviders,
    {
      variables: {
        partnerId,
        projectId,
        ...getQueryVariables(sortBy, search),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  )

  const refetchProjectSettingsData = () => {
    refetchProjectSettings({
      partnerId,
      projectId,
    })
  }
  // Refetch on mount
  useEffect(() => {
    refetchProjectSettingsData()
  }, [])

  // Refetch when filters change
  useEffect(() => {
    refetch({
      partnerId,
      projectId,
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, partnerId, projectId, refetch])

  /**
   * Constructs the URL by replacing the partnerId and section with the
   * current tab, and appends the search and sort parameters.
   */
  function navigateToURL(params) {
    const baseURL = PARTNERS.projects.admin.section
      .replace(':partnerId', partnerId)
      .replace(':projectId', projectId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    navigateToURL(urlParams)
    setSearch(value)
  }

  // Delete lab project
  const [deletePartnerProjectProvider] = useMutation(
    DeletePartnerProjectProvider,
  )

  const [modals, { showConfirmationModal }] = useSavingModals({
    confirmationMessage:
      'Are you sure you want to remove this lab from the project?',
    savingMessage: "We're executing the operation, please wait...",
    savedMessage: 'Lab removed.',
    errorMessage: 'Error removing lab',
    onConfirm: ({ providerId }) => {
      deletePartnerProjectProvider({
        variables: { partnerId, projectId, providerId },
        refetchQueries: [
          {
            query: ListPartnerProjectProviders,
            variables: {
              partnerId,
              projectId,
              ...getQueryVariables(sortBy, search),
            },
          },
        ],
      })
    },
  })

  function handleDeletePackage(providerId) {
    showConfirmationModal({ providerId })
  }

  // Set default lab in project
  const [updatePartnerProject] = useMutation(UpdatePartnerProject)

  const [selectedProvider, setSelectedProvider] = useState()
  const [
    defaultLabModal,
    { showConfirmationModal: showConfirmationModalSetLabDefault },
  ] = useSavingModals({
    confirmationMessage: `Are you sure you want to set "${selectedProvider}" as the default provider for this project?`,
    savingMessage: "We're executing the operation, please wait...",
    savedMessage: 'Lab set as default.',
    errorMessage: 'Error setting this lab as default',
    onConfirm: ({ provider }) => {
      updatePartnerProject({
        variables: {
          partnerId,
          projectId,
          data: {
            provider_id: provider ? provider.provider.id : null,
          },
        },
        refetchQueries: [
          {
            query: GetPartnerProjectSettings,
            variables: {
              partnerId,
              projectId,
            },
          },
        ],
      })
    },
  })

  function handleSetLabDefault(provider) {
    setSelectedProvider(provider.provider.name)
    showConfirmationModalSetLabDefault({ provider })
  }

  const defaultProvider = projectSettingsData?.getPartnerProject.provider

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <Search
          className={style.search}
          placeholder="Search Lab"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button
          onClick={() => push('labs/add-lab')}
          data-test="button-add-package"
          size={Button.size.small}
        >
          Add Lab to Project
        </Button>
      </div>
      <LabsList
        data={camelizeKeys(data)}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
        onDeleteClick={handleDeletePackage}
        onSetDefaultLab={handleSetLabDefault}
        defaultProvider={defaultProvider}
      />

      {/* Modals */}
      {modals}
      {defaultLabModal}
    </div>
  )
}
