import React, { useState, useEffect } from 'react'

import { Dropdown } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Search, Button, useSavingModals } from '@labsavvyapp/ui-components'
import _ from 'lodash'

import style from './PackagesTab.module.css'
import { SORT_BY_OPTIONS } from './constants'
import PackagesList from './PackagesList/PackagesList'
import { updateURLParameter } from '../../../../utils/urls'
import { PARTNERS } from '../../../../config/routes'
import { ListPartnerProjectPackages } from '../../../../graphql/packages/queries.js'
import { DeletePartnerProjectPackage } from '../../../../graphql/partner/mutations.js'
import { ListPartnerProjectProviders } from '../../../../graphql/labs/queries'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = SORT_BY_OPTIONS.nameZA.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function PackagesTab() {
  const { push } = useHistory()
  const { partnerId, projectId, section } = useParams()
  const [provider, setProvider] = useState()
  const [providers, setProviders] = useState()

  const query = new URLSearchParams(window.location.search)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // Fetch packages list
  const { data, networkStatus, fetchMore, refetch } = useQuery(
    ListPartnerProjectPackages,
    {
      variables: {
        partnerId,
        projectId,
        providerId: provider?.provider?._id || '000000000000000000000000',
        ...getQueryVariables(sortBy, search),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  )

  // Refetch when filters change
  useEffect(() => {
    refetch({
      partnerId,
      projectId,
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, partnerId, projectId, refetch])

  /**
   * Constructs the URL by replacing the partnerId and section with the
   * current tab, and appends the search and sort parameters.
   */
  function navigateToURL(params) {
    const baseURL = PARTNERS.projects.admin.section
      .replace(':partnerId', partnerId)
      .replace(':projectId', projectId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    navigateToURL(urlParams)
    setSearch(value)
  }

  // Delete partner project
  const [deletePartnerProjectPackage] = useMutation(DeletePartnerProjectPackage)

  const [modals, { showConfirmationModal }] = useSavingModals({
    confirmationMessage:
      'Are you sure you want to delete this package from the project?',
    savingMessage: "We're executing the operation, please wait...",
    savedMessage: 'Package deleted.',
    errorMessage: 'Error deleting package',
    onConfirm: ({ packageId }) => {
      deletePartnerProjectPackage({
        variables: { partnerId, projectId, packageId },
        refetchQueries: [
          {
            query: ListPartnerProjectPackages,
            variables: {
              partnerId,
              projectId,
              providerId: provider?.provider._id || '000000000000000000000000',
              ...getQueryVariables(sortBy, search),
            },
          },
        ],
      })
    },
  })

  function handleDeletePackage(packageId) {
    showConfirmationModal({ packageId })
  }

  // Fetch packages list
  const { data: providerList } = useQuery(ListPartnerProjectProviders, {
    variables: {
      partnerId,
      projectId,
      ...getQueryVariables(sortBy, search),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  if (providerList?.listPartnerProjectProviders && !providers) {
    setProviders(providerList.listPartnerProjectProviders)
    setProvider(providerList.listPartnerProjectProviders[0])
  }

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, (o) => {
      return o.provider._id === value
    })
    setProvider(provider)
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        {data?.listPartnerProjectPackages?.packages.length > 0 && (
          <>
            <Search
              className={style.search}
              placeholder="Search Packages"
              showNoResults={false}
              searchText={search}
              onSearchChange={handleSearchChange}
            />
            <div>
              <span className={style.dropdownLabel}>Sort by:</span>
              <Dropdown
                selection
                className={style.dropdown}
                value={sortBy}
                options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                  text: SORT_BY_OPTIONS[option].text,
                  value: SORT_BY_OPTIONS[option].key,
                  key: SORT_BY_OPTIONS[option].key,
                }))}
                onChange={handleSortChange}
                data-test="dropdown-sort"
              />
            </div>
          </>
        )}

        {providers && (
          <>
            <div className={style.dropdownLabel}>Laboratory:</div>
            <Dropdown
              selection
              className={style.dropdown}
              options={providers.map((item) => ({
                text: item?.provider?.name,
                value: item?.provider?._id,
                key: item?.provider?._id,
              }))}
              onChange={handleSetProvider}
              defaultValue={provider?.provider?._id}
            />
          </>
        )}

        <Button
          onClick={() => push('packages/add-package')}
          data-test="button-add-package"
          size={Button.size.small}
        >
          Add Package
        </Button>
      </div>

      <PackagesList
        data={camelizeKeys(data)}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
        onDeleteClick={handleDeletePackage}
        provider={provider}
      />

      {/* Modals */}
      {modals}
    </div>
  )
}
