import React from 'react'
import { Header, Grid, Icon } from 'semantic-ui-react'

import { useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import {
  Button,
  InputField,
  SubmitButton,
  Form,
  validate,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import style from './CategoryForm.module.css'
import { ListTestReferenceCategories } from '../../graphql/categories/queries.js'
import {
  CreateTestReferenceCategory,
  UpdateTestReferenceCategory,
  DeleteTestReferenceCategory,
} from '../../graphql/categories/mutations.js'

const refetchQueries = [
  {
    query: ListTestReferenceCategories,
    variables: {
      limit: 50,
      page: 1,
      filter: {},
    },
  },
]

function CategoryForm({ data, onUpdate, onDelete }) {
  const [createCategory] = useMutation(CreateTestReferenceCategory, {
    refetchQueries,
  })
  const [updateCategory] = useMutation(UpdateTestReferenceCategory, {
    refetchQueries,
  })
  const [deleteCategory] = useMutation(DeleteTestReferenceCategory, {
    refetchQueries,
  })

  // Delete modals
  const [deleteModals, { showConfirmationModal: showDeleteConfirmationModal }] =
    useSavingModals({
      savingMessage: "We're deleting your category, please wait...",
      savedMessage: 'The category has been deleted.',
      confirmationMessage: 'Do you really want to delete this category?',
      onConfirm: () =>
        deleteCategory({
          variables: { id: data.id },
        }),
      onSuccess: () => onDelete(),
    })

  // Create/ update modals
  const [saveModals, { showModals: showSavingModals }] = useSavingModals({
    savingMessage: "We're saving your category, please wait...",
    savedMessage: 'The category has been saved.',
    callback: async ({ name }) => {
      if (data) {
        const {
          data: { updateTestReferenceCategory },
        } = await updateCategory({
          variables: {
            id: data.id,
            data: { name },
          },
        })
        onUpdate(camelizeKeys(updateTestReferenceCategory))
      } else {
        const {
          data: { createTestReferenceCategory },
        } = await createCategory({
          variables: {
            data: { name },
          },
        })
        onUpdate(camelizeKeys(createTestReferenceCategory))
      }
    },
  })

  const handleFormSubmit = (formData) => {
    showSavingModals(formData)
  }

  const handleDeleteClick = () => {
    showDeleteConfirmationModal()
  }

  return (
    <>
      <Form
        className={style.form}
        initialFormData={data || undefined}
        key={data ? data.id : 'new-category'}
        onSubmit={handleFormSubmit}
      >
        {!data && <Header>Create category</Header>}

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <InputField
                name="name"
                label="Name"
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8} className={style.deleteButtonContainer}>
              {data && (
                <Button
                  data-test="delete-category"
                  onClick={handleDeleteClick}
                  className={style.deleteButton}
                  variant={Button.variant.basic}
                >
                  <Icon name="trash" /> Delete
                </Button>
              )}
            </Grid.Column>
            <Grid.Column width={8} className={style.saveButtonContainer}>
              <SubmitButton data-test="add-category-submit">Save</SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {deleteModals}

      {saveModals}
    </>
  )
}
export default CategoryForm
