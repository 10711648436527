import gql from 'graphql-tag'

export const CreateTestReferenceCategory = gql`
  mutation CreateTestReferenceCategory($data: TestReferenceCategoryInput!) {
    createTestReferenceCategory(data: $data) {
      _id
      name
    }
  }
`

export const UpdateTestReferenceCategory = gql`
  mutation UpdateTestReferenceCategory(
    $id: MongoID!
    $data: TestReferenceCategoryInput!
  ) {
    updateTestReferenceCategory(id: $id, data: $data) {
      _id
      name
    }
  }
`

export const DeleteTestReferenceCategory = gql`
  mutation DeleteTestReferenceCategory($id: MongoID!) {
    deleteTestReferenceCategory(id: $id) {
      _id
    }
  }
`
