import React from 'react'

import MainLayout from './MainLayout'

const withMainLayout = (
  Component,
  { noPadding, logged, user } = { noPadding: false, logged: false, user: {} },
) => (
  <MainLayout noPadding={noPadding} logged={logged} user={user}>
    <Component />
  </MainLayout>
)
export default withMainLayout
