import React from 'react'

import { useHistory, useParams } from 'react-router'
import {
  InfiniteScrollList,
  List,
  ListRow,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import {
  hasMore,
  fetchNext,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'
import { utc } from 'moment'
import classnames from 'classnames'

import { PARTNERS } from '../../../config/routes'
import style from './ProjectsList.module.css'

function ListHeader() {
  return (
    <div className={style.header}>
      <div className={style.column}>Project Name</div>
      <div className={style.column}>Laboratory</div>
      <div className={style.column}>Date Created</div>
      <div className={style.column}>Status</div>
    </div>
  )
}

function ListItems({ items }) {
  const { push } = useHistory()
  const { partnerId } = useParams()

  return items.map((item) => (
    <ListRow
      key={item.id}
      className={style.row}
      data-test="project-list-row"
      onClick={() =>
        push(
          PARTNERS.projects.admin.base
            .replace(':partnerId', partnerId)
            .replace(':projectId', item.id)
            .concat('/general'),
        )
      }
    >
      <div className={style.column}>{item.name}</div>
      <div className={style.column}>{item.provider && item.provider.name}</div>
      <div className={style.column}>
        {utc(item.createdAt).format('MM/DD/YYYY')}
      </div>
      <div className={classnames(style.column, style.status)}>
        {item.status}
      </div>
    </ListRow>
  ))
}

export default function ProjectsList({ data, loading, fetchMore }) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
  }

  if (data) {
    items = data.listPartnerProjects.projects
    pagination.page = data.listPartnerProjects.page
    pagination.pages = data.listPartnerProjects.pages
  }

  return (
    <List>
      <InfiniteScrollList
        dataLength={items.length}
        HeaderElement={<ListHeader />}
        scrollableTarget="projects-list"
        hasMore={hasMore(pagination)}
        next={() => fetchNext('listPartnerProjects', { pagination, fetchMore })}
      >
        {!loading && isEmpty(items) && (
          <ListEmpty message="No projects were found." />
        )}

        {!isEmpty(items) && <ListItems items={items} />}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
