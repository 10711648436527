import React from 'react'
import moment from 'moment'
import delay from 'await-delay'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { generatePath, useHistory, useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'
import {
  Form,
  InputField,
  SubmitButton,
  useSavingModals,
  SelectField,
  validate,
} from '@labsavvyapp/ui-components'
import { camelizeKeys } from 'humps'
import { PARTNERS } from '../../../../config/routes'

// Queries & Mutations
import { ListProviders } from '../../../../graphql/providers/queries.js'
import { AddMiscPricingType } from '../../../../graphql/partner/mutations.js'

// Styles
import sharedStyles from '../../../../styles/shared.module.css'
import style from './FormTab.module.css'

export default function FormTab() {
  const { push } = useHistory()
  const { partnerId } = useParams()

  const miscCode = moment().unix()
  const [addMiscPricingType] = useMutation(AddMiscPricingType, {
    async onCompleted({ addMiscPricingType }) {
      await delay(1000)
      push(
        generatePath(PARTNERS.admin.section, {
          partnerId: addMiscPricingType._id,
          section: 'misc-pricing',
        }),
      )
    },
  })

  const showModalsCallback = async ({ providerId, misc_pricing_type_name }) => {
    await addMiscPricingType({
      variables: {
        partnerId,
        providerId,
        data: {
          code: 'LSMC' + miscCode,
          name: misc_pricing_type_name,
          contract_price: 0,
          retail_price: 0,
        },
      },
    })
  }

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage:
      "We're saving the miscellaneous pricing type, please wait...",
    savedMessage: 'A new miscellaneous pricing entry has been saved.',
    callback: showModalsCallback,
  })

  // Fetch packages in order to populate the dropdown
  const { data } = useQuery(ListProviders)
  const providersData = camelizeKeys(data)
  const providersOptions = providersData
    ? providersData.listProviders.providers.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      }))
    : []

  return (
    <div className={style.formContainer} style={{ paddingLeft: 15 }}>
      {modals}
      <Form onSubmit={showModals}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="providerId"
                label="Lab Name"
                options={providersOptions}
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="misc_pricing_type_name"
                label="Misc Pricing Name"
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SubmitButton className={sharedStyles.marginTop10}>
                Add Miscellaneous Type
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  )
}
