export const ROLES_OPTIONS = Object.freeze({
  consultant: 'Consultant',
})

export const SORT_BY_OPTIONS = Object.freeze({
  nameAZ: {
    value: 'asc',
    text: 'Name A-Z',
    key: 'nameAZ',
  },
  nameZA: {
    value: 'desc',
    text: 'Name Z-A',
    key: 'nameZA',
  },
})

export const COMMISSION = Object.freeze({
  min: 0,
  max: 100,
})
