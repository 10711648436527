import React, { useEffect } from 'react'
import { Button, Header, Table, Rating, Input, Grid } from 'semantic-ui-react'
import {
  Form,
  InputField,
  SubmitButton,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import { useHistory, useLocation } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GetAllConfigs } from '../../../graphql/settings/queries'
import { UpdateConfig } from '../../../graphql/settings/mutations'
import style from './ConfigurationPage.module.css'

import { SETTINGS } from '../../../config/routes'

export default function AddEditConfigurationPage() {
  const { push, goBack } = useHistory()
  const { state } = useLocation()

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving this configuration, please wait...",
    savedMessage: 'The configuration has been saved.',
    callback: async (formData) => {
      await updateConfig({
        variables: {
          input: {
            id: formData._id,
            name: formData.name,
            placeholder: formData.name,
            description: formData.description,
            value: formData.value,
            value2: formData.value2,
            value3: formData.value3,
          },
        },
      })
      setTimeout(() => {
        goBack()
      }, 2000)
    },
  })

  const [updateConfig] = useMutation(UpdateConfig)

  return (
    <>
      {modals}
      <Form initialFormData={state?.config} onSubmit={showModals}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="name" label="Config Name" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="description" label="Description" />
            </Grid.Column>
          </Grid.Row>

          {/* <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <InputField name="domain" label="Domain" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <InputField name="subdomain" label="Subdomain" />
          </Grid.Column>
        </Grid.Row> */}

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="value" label="Value" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="value2" label="Value2" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField name="value3" label="Value3" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton>Save Changes</SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
