import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Form,
  InputField,
  SelectField,
  SubmitButton,
  useSavingModals,
  validate,
} from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'

import {
  TIME_ZONE_OPTIONS,
  CONSUMER_NAME_OPTIONS,
  SWITCHES_ON_OFF,
} from './constants'
import sharedStyles from '../../../../styles/shared.module.css'
import { ListProviders } from '../../../../graphql/providers/queries.js'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations.js'
import { GetPartnerProjectSettings } from '../../../../graphql/partner/queries.js'
import { isValidCode } from '../../../../utils/validate-code'
export default function SettingsTab() {
  const { partnerId, projectId } = useParams()
  const [partnerCode, setPartnerCode] = useState(null)
  const [useCoBranding, setUseCoBranding] = useState(false)

  const { formatMessage } = useIntl()
  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving your data, please wait...",
    savedMessage: 'Your data has been saved.',
    callback: async (formData) => {
      const {
        name,
        consumers_title,
        timezone,
        provider,
        partner_code,
        use_co_branding,
        use_shop,
      } = formData
      const useBranding = use_co_branding === 'true'
      const useShop = use_shop === 'true'
      await updatePartnerProject({
        variables: {
          partnerId,
          projectId,
          data: {
            name,
            consumers_title,
            timezone,
            partner_code,
            use_co_branding: useBranding,
            use_shop: useShop,
            provider_id: provider ? provider._id : null,
          },
        },
      })
    },
  })

  const { data } = useQuery(GetPartnerProjectSettings, {
    variables: { partnerId, projectId },
  })

  if (data && partnerCode === null) {
    setPartnerCode(!!data.getPartnerProject.partner_code)
    setUseCoBranding(data.getPartnerProject.use_co_branding)
    data.getPartnerProject.use_co_branding = `${data.getPartnerProject.use_co_branding}`
    data.getPartnerProject.use_shop = `${data.getPartnerProject.use_shop}`
  }

  const changePartnerCode = (value) => {
    setPartnerCode(!!value)
  }

  const { data: providersData, loading: providersLoading } =
    useQuery(ListProviders)
  const providerOptions =
    (providersData &&
      providersData.listProviders.providers.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      }))) ||
    []

  const [updatePartnerProject] = useMutation(UpdatePartnerProject)

  return (
    <>
      <Form
        initialFormData={data && data.getPartnerProject}
        onSubmit={showModals}
        intl={{ formatMessage }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="consumers_title"
                label="What do you call your consumers?"
                options={CONSUMER_NAME_OPTIONS}
              />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="partner_code"
                label="Partner Code (optional)"
                validate={
                  useCoBranding
                    ? validate.combine(validate.notEmpty(), isValidCode)
                    : isValidCode
                }
                onChange={changePartnerCode}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="timezone"
                label="Time Zone"
                options={TIME_ZONE_OPTIONS}
              />
            </Grid.Column>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="use_co_branding"
                label="Mobile App Co-Branding (requires partner code)"
                options={SWITCHES_ON_OFF}
                disabled={!partnerCode}
                onChange={(value) => setUseCoBranding(value === 'true')}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {/* <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="provider._id"
                label="Laboratory / Compendium"
                options={providerOptions}
                loading={providersLoading}
              />
            </Grid.Column> */}
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="use_shop"
                label="Shop (in-app e-commerce)"
                options={SWITCHES_ON_OFF}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
