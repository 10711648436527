import React from 'react'
import { Icon } from 'semantic-ui-react'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import style from './MainPricingList.module.css'
import MainPricingRow from './MainPricingRow'

export default function MainPricingList({
  data,
  fetchMore,
  loading,
  provider,
  search,
  networkStatus,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.mainPricing.data
    pagination.page = data.mainPricing.page
    pagination.pages = data.mainPricing.pages
    pagination.total = data.mainPricing.total
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Test Code',
            className: style.codeColumn,
          },
          { name: 'Test Name', className: style.nameColumn },
          {
            name: 'LS Cost',
            className: style.lsCostColumn,
          },
          {
            name: 'LS Standard Price',
            className: style.lsStandardPriceColumn,
          },
          {
            name: 'Standard Retail Price',
            className: style.lsStRtPriceColumn,
          },
        ]}
      />
      {/* {networkStatus !== 7 && !search && (
        // <ListLoader
        //   loading
        //   fetch={data?.mainPricing.data.length}
        //   total={data?.mainPricing.total}
        // />
        <Card className={style.centered}>
          <b>
            <Icon name="spinner" loading size="big" /> Loading List! Please
            wait. {items.length}/{pagination.total}
          </b>
        </Card>
      )} */}
      <InfiniteScrollList
        style={{ zIndex: -1 }}
        dataLength={items.length}
        scrollableTarget="main-pricing-list"
        hasMore={hasMore({ page: pagination.page, pages: pagination.pages })}
        next={() =>
          fetchNext('mainPricing', 'data', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && !isEmpty(items) && (
          <MainPricingRow items={items} provider={provider} />
        )}
        {networkStatus === 7 && search && isEmpty(items) && (
          <Card className={style.centered}>
            <b>
              <Icon name="warning" size="big" /> Empty! No Record(s) Found
            </b>
          </Card>
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={networkStatus !== 7 && !search}
        />
      </InfiniteScrollList>
    </List>
  )
}
