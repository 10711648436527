import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'

import {
  Form,
  SelectField,
  SubmitButton,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { GetPartnerSettings } from '../../../../graphql/partner/queries.js'
import { UpdatePartner } from '../../../../graphql/partner/mutations.js'
import { TIME_ZONE_OPTIONS } from './constants'
import sharedStyles from '../../../../styles/shared.module.css'

export default function SettingsTab() {
  const { partnerId } = useParams()
  const [updatePartner] = useMutation(UpdatePartner)

  const { data: partnerData } = useQuery(GetPartnerSettings, {
    variables: {
      id: partnerId,
    },
  })

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the partner, please wait...",
    savedMessage: 'The partner has been saved.',
    callback: async ({ timezone }) => {
      await updatePartner({
        variables: {
          id: partnerId,
          data: {
            timezone,
          },
        },
      })
    },
  })

  return (
    <>
      {/* Modals */}
      {modals}

      {/* Form */}
      <Form
        initialFormData={partnerData && partnerData.getPartner}
        onSubmit={showModals}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="timezone"
                label="Time Zone"
                options={TIME_ZONE_OPTIONS}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
