import gql from 'graphql-tag'

export const ListProviders = gql`
  query ListProviders {
    listProviders(limit: 20) {
      providers {
        _id
        name
      }
    }
  }
`
