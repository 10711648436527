import React, { useCallback, useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory, useLocation, useParams, generatePath } from 'react-router'
import { camelizeKeys } from 'humps'

import { COMMISSION, SORT_BY_OPTIONS } from './constants'

// Styling
import {
  Form,
  SelectField,
  SubmitButton,
  InputField,
  PercentageField,
  validate,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import sharedStyles from '../../../../styles/shared.module.css'

// Queries and Mutations
import { ListPartnerProjectProviders } from '../../../../graphql/labs/queries'
import { AddPartnerProjectPackage } from '../../../../graphql/packages/mutations.js'
import { ListPackages } from '../../../../graphql/packages/queries.js'
import { PARTNERS } from '../../../../config/routes'

export default function AddPackageTab() {
  const { push } = useHistory()

  const { search: locationSearch } = useLocation()

  const { partnerId, projectId } = useParams()
  const [providerId, setProviderId] = useState()
  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // Set laboratory options (defaults to first entry)
  const [laboratory, setLaboratory] = useState()

  const getQueryVariables = useCallback(() => {
    const variables = {
      limit: 30,
      sort: {},
      filter: { provider_id: providerId },
    }

    switch (sortBy) {
      case SORT_BY_OPTIONS.nameAZ.key:
        variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
        break
      case SORT_BY_OPTIONS.nameZA.key:
        variables.sort.name = SORT_BY_OPTIONS.nameZA.value
        break
      case SORT_BY_OPTIONS.createdAtAsc.key:
        variables.sort.created_at = SORT_BY_OPTIONS.createdAtAsc.value
        break
      case SORT_BY_OPTIONS.createdAtDesc.key:
        variables.sort.created_at = SORT_BY_OPTIONS.createdAtDesc.value
        break
      default:
        break
    }

    if (search) {
      variables.filter.name = search
    }

    if (laboratory) {
      variables.filter.provider_id = laboratory
    }

    return variables
  }, [laboratory, search, sortBy, providerId])

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're adding the package, please wait...",
    savedMessage: 'Package added.',
    callback: async ({ packageId, name, commission, providerId }) => {
      await addPartnerProjectPackage({
        variables: {
          partnerId,
          projectId,
          packageId,
          providerId,
          name,
          commission: commission || 0,
        },
      })
      setTimeout(() => {
        push(
          generatePath(PARTNERS.projects.admin.section, {
            partnerId: partnerId,
            projectId: projectId,
            section: 'packages',
          }),
        )
      }, 3000)
    },
    onError: (error) => error,
  })

  // Fetch packages in order to populate the dropdown
  const { data } = useQuery(ListPackages, {
    variables: getQueryVariables(),
    notifyOnNetworkStatusChange: true,
  })
  const packagesData = camelizeKeys(data)
  const packagesOptions = packagesData
    ? packagesData.listPackages.packages.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      }))
    : []

  const [addPartnerProjectPackage] = useMutation(AddPartnerProjectPackage)

  // Fetch packages in order to populate the dropdown
  const { data: providersList } = useQuery(ListPartnerProjectProviders, {
    variables: {
      partnerId,
      projectId,
    },
  })
  const providersData = camelizeKeys(providersList)

  const providersOptions = providersData
    ? providersData.listPartnerProjectProviders.map(({ provider }) => ({
        key: provider.id,
        value: provider.id,
        text: provider.name,
      }))
    : []

  const handleSelectLab = (providerId) => {
    setProviderId(providerId)
  }
  return (
    <>
      <Form onSubmit={showModals}>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="providerId"
                label="Lab Name"
                options={providersOptions}
                validate={validate.notEmpty()}
                onChange={handleSelectLab}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="packageId"
                label="Package Name"
                options={packagesOptions}
                validate={validate.notEmpty()}
                disabled={!providerId}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="name"
                label="Custom Name"
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <PercentageField
                label="Commission (0-100)"
                name="commission"
                validate={validate.combine(
                  validate.minValue(COMMISSION.min),
                  validate.maxValue(COMMISSION.max),
                )}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Add Package
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
