export const ROLES_OPTIONS = Object.freeze({
  consultant: 'Consultant',
})

export const SORT_BY_OPTIONS = Object.freeze({
  nameAZ: {
    value: 'asc',
    text: 'Name A-Z',
    key: 'nameAZ',
  },
  nameZA: {
    value: 'desc',
    text: 'Name Z-A',
    key: 'nameZA',
  },
  roleAZ: {
    value: 'asc',
    text: 'Role A-Z',
    key: 'roleAZ',
  },
  roleZA: {
    value: 'desc',
    text: 'Role Z-A',
    key: 'roleZA',
  },
})
