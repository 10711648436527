import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router'
import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'
import { Button, Search } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../styles/shared.module.css'
import style from './TeamMembersPage.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { TEAM_MEMBERS } from '../../../config/routes'
import { SORT_BY_OPTIONS } from './constants'
import TeamMembersList from './TeamMembersList/TeamMembersList'
import { updateURLParameter } from '../../../utils/urls'
import { ListAdminTeamMembers } from '../../../graphql/team-members/queries.js'

export default function TeamMembersPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // TODO: Find a better solution for this
  const getQueryVariables = useCallback(() => {
    const variables = {
      limit: 30,
      sort: {},
      filter: {},
    }

    switch (sortBy) {
      case SORT_BY_OPTIONS.nameAZ.key:
        variables.sort.name = { first: SORT_BY_OPTIONS.nameAZ.value }
        break
      case SORT_BY_OPTIONS.nameZA.key:
        variables.sort.name = { first: SORT_BY_OPTIONS.nameZA.value }
        break
      case SORT_BY_OPTIONS.roleAZ.key:
        variables.sort.role = { priority: SORT_BY_OPTIONS.roleAZ.value }
        break
      case SORT_BY_OPTIONS.roleZA.key:
        variables.sort.role = { priority: SORT_BY_OPTIONS.roleZA.value }
        break
      default:
        break
    }

    if (search) {
      variables.filter = { name: search }
    }

    return variables
  }, [search, sortBy])

  const {
    data: usersData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(ListAdminTeamMembers, {
    variables: getQueryVariables(),
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      ...getQueryVariables(),
      page: 1,
    })
  }, [sortBy, search, getQueryVariables, refetch])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`team-members?${urlParams}`)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`team-members?${urlParams}`)
    setSearch(value)
  }

  return (
    <MainContainer>
      <Helmet>
        <title>Team Members</title>
      </Helmet>
      <header className={style.header}>
        <div style={{ marginTop: 8 }}>
          <h1 className={classnames(sharedStyle.pageTitle, style.pageTitle)}>
            Team Members
          </h1>
        </div>

        <div className={style.tools}>
          <div>
            <span className={style.dropdownLabel}>Sort by:</span>
            <Dropdown
              selection
              className={style.dropdown}
              value={sortBy}
              options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                text: SORT_BY_OPTIONS[option].text,
                value: SORT_BY_OPTIONS[option].key,
                key: SORT_BY_OPTIONS[option].key,
              }))}
              onChange={handleSortChange}
            />
          </div>

          <Search
            className={style.search}
            placeholder="Search by Name"
            showNoResults={false}
            searchText={search}
            onSearchChange={handleSearchChange}
          />

          <Button onClick={() => push(TEAM_MEMBERS.invite)}>
            Invite Team Member
          </Button>
        </div>
      </header>

      <TeamMembersList
        data={usersData && usersData.listAdminTeamMembers}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
      />
    </MainContainer>
  )
}
