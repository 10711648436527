import React, { useState, useEffect } from 'react'
import style from './PackageFormPage.module.css'
import { camelizeKeys } from 'humps'

export function usePanels({ packageData }) {
  const [initialSelectedPanels, setInitialSelectedPanels] = useState([])
  const [selectedPanels, setSelectedPanels] = useState([])

  useEffect(() => {
    const panelsFromApi =
      packageData && camelizeKeys(packageData.getPackage.categories)

    if (panelsFromApi) {
      setInitialSelectedPanels([...panelsFromApi])
      setSelectedPanels(panelsFromApi)
    }
  }, [packageData])

  const getSelectedPanelsCheckList = () =>
    selectedPanels.map(({ category, panels }) => {
      const flatIndividualTests = getFlatTests(
        panels.filter(({ code }) => code === ''),
      )

      const individualTests = flatIndividualTests.map(({ id, result }) => ({
        text: (
          <div className={style.checkListTest}>
            <span className={style.checkListTestCode}>{result.code}</span>
            <span>{result.name}</span>
          </div>
        ),
        value: id,
      }))

      return {
        category: category && {
          id: category.id,
          name: category.name,
        },
        // Individual checklist items
        individualTests,
        // Groups checklist items.
        panels: panels
          .filter(({ code }) => code !== '')
          .map(({ code, name, tests }) => {
            const panelTests = tests.map(({ result }) => ({
              text: (
                <div className={style.checkListTest}>
                  <span className={style.checkListTestCode}>{result.code}</span>
                  <span>{result.name}</span>
                </div>
              ),
              value: result.code,
            }))

            return {
              panel: { code, name },
              tests: panelTests,
            }
          }),
      }
    })

  const hasPanelsChanged = () => {
    if (countPanels(initialSelectedPanels) !== countPanels(selectedPanels)) {
      return true
    }

    const selectedFlatTests = getFlatPanels(selectedPanels)

    return getFlatPanels(initialSelectedPanels).some(
      ({ id }) => selectedFlatTests.indexOf(id) === -1,
    )
  }

  const countPanels = (panelsList) =>
    panelsList.reduce((acc, { panels }) => acc + panels.length, 0)

  const getFlatTests = (panels) =>
    panels.reduce((acc, { tests }) => [...acc, ...tests], [])

  const getFlatPanels = (panelsList) =>
    panelsList.reduce((acc, { panels }) => [...acc, getFlatTests(panels)], [])

  return {
    setInitialSelectedPanels,
    selectedPanels,
    setSelectedPanels,

    getSelectedPanelsCheckList,

    hasPanelsChanged,
  }
}
