import gql from 'graphql-tag'

export const UserEmails = gql`
  fragment UserEmails on User {
    emails {
      address
    }
  }
`

export const GetSession = gql`
  query GetSession {
    session @client {
      token
      isLoggedIn
    }
  }
`

export const AdminListUsers = gql`
  query AdminListUsers(
    $page: NonNegativeInt = 1
    $filter: UserFilter
    $sort: UserSort = { name: { first: asc } }
  ) {
    adminListUsers(page: $page, limit: 20, filter: $filter, sort: $sort) {
      users {
        _id
        name {
          display
        }
        emails {
          address
        }
        phones {
          number
        }
        capabilities {
          isSuperAdmin
        }
      }
      page
      pages
      total
    }
  }
`

export const GetUser = gql`
  query GetUser($id: MongoID!) {
    getUser(id: $id) {
      name {
        first
        last
      }
      dob
      gender
      emails {
        address
      }
      roles {
        key
      }
    }
  }
`

export const UserDisplayName = gql`
  fragment UserDisplayName on UserName {
    display
  }
`

export const GetMe = gql`
  query GetMe {
    getMe {
      _id
      name {
        ...UserDisplayName
      }
      capabilities {
        isSuperAdmin
      }
    }
  }

  ${UserDisplayName}
`

export const GetProfile = gql`
  query GetProfile {
    getMe {
      _id
      name {
        first
        last
        ...UserDisplayName
      }
      ...UserEmails
      profile_photo {
        _id
        url
      }
    }
  }

  ${UserEmails}
  ${UserDisplayName}
`

export const ExistUserEmail = gql`
  query ExistUserEmail($email: String!) {
    existUserEmail(email: $email)
  }
`

export const GetUserEmails = gql`
  query GetUserEmails {
    getMe {
      _id
      ...UserEmails
    }
  }

  ${UserEmails}
`
