import React from 'react'

import { useHistory } from 'react-router'
import classnames from 'classnames'
import {
  InfiniteScrollList,
  List,
  ListRow,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import { utc } from 'moment'

import style from './PartnersList.module.css'
import { PARTNERS } from '../../../config/routes'

function ListHeader() {
  return (
    <div className={style.header}>
      <div className={classnames(style.column, style.organizationColumn)}>
        Partner
      </div>
      <div className={style.column}>Contact</div>
      <div className={style.column}>Date Created</div>
      <div className={style.column}>Status</div>
    </div>
  )
}

function ListItems({ items }) {
  const { push } = useHistory()

  return items.map((item) => (
    <ListRow
      key={item.id}
      className={style.row}
      data-test="partner-list-row"
      onClick={() => push(`${PARTNERS.base}/${item.id}`)}
    >
      <div className={classnames(style.column, style.organizationColumn)}>
        {item.name}
      </div>
      <div className={style.column}>
        {item.contact && item.contact.name.display}
      </div>
      <div className={style.column}>
        {utc(item.createdAt).format('MM/DD/YYYY')}
      </div>
      <div className={classnames(style.column, style.status)}>
        {item.status || '-'}
      </div>
    </ListRow>
  ))
}

function PartnersList({ data, loading, fetchMore }) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
  }

  if (data) {
    items = data.listPartners.partners
    pagination.page = data.listPartners.page
    pagination.pages = data.listPartners.pages
  }

  return (
    <List>
      <InfiniteScrollList
        dataLength={items.length}
        HeaderElement={<ListHeader />}
        scrollableTarget="partners-list"
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('listPartners', 'partners', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(items) && (
          <ListEmpty message="No partners were found." />
        )}

        {!isEmpty(items) && <ListItems items={items} />}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}

export default PartnersList
