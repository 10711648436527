export const SORT_BY_OPTIONS = Object.freeze({
  nameAZ: {
    value: 'asc',
    text: 'Name A-Z',
    key: 'nameAZ',
  },
  nameZA: {
    value: 'desc',
    text: 'Name Z-A',
    key: 'nameZA',
  },
  createdAtAsc: {
    value: 'asc',
    text: 'Date Ascending',
    key: 'createdAtAsc',
  },
  createdAtDesc: {
    value: 'desc',
    text: 'Date Descending',
    key: 'createdAtDesc',
  },
})
