import React, { useState, useEffect, useCallback } from 'react'
import {
  useHistory,
  useLocation,
  useParams,
  matchPath,
  generatePath,
} from 'react-router'

import { ListMenu, Tabs } from '@labsavvyapp/ui-components'

import MainContainer from '../MainContainer/MainContainer'
import style from './TabsLayout.module.css'

export default function TabsLayout({
  Header,
  menuItems = [],
  selectedMenuItem,
}) {
  // Router
  const { push } = useHistory()
  const { pathname } = useLocation()
  const urlsParams = useParams()

  // Menu items and tab states
  const [localSelectedMenuItem, setSelectedMenuItem] = useState(
    selectedMenuItem || pathname,
  )
  const [selectedTabs, setSelectedTabs] = useState([])
  const [selectedTabIndex, setSelectedTabIndex] = useState()

  const getCurrentMenuItem = useCallback(() => {
    const foundMenuItem = menuItems.find(({ url }) =>
      matchPath(pathname, { path: url }),
    )

    return foundMenuItem || menuItems[0]
  }, [menuItems, pathname])

  /**
   * Filter the available tabs on a given menu item.
   */
  const getAvailableTabs = useCallback(
    (menuItem) => {
      return menuItem.tabs.reduce((newTabs, tab) => {
        if (!tab.visibleURLCondition) return [...newTabs, tab]
        if (
          matchPath(pathname, { path: tab.visibleURLCondition, exact: true })
        ) {
          return [...newTabs, tab]
        }
        return newTabs
      }, [])
    },
    [pathname],
  )

  /**
   * Finds the next active tab index according the the URL parameters.
   */
  const findTabItemIndex = useCallback(() => {
    if (selectedTabs.length === 1) {
      return 0
    }

    const foundIndex = selectedTabs.findIndex(({ url }) => {
      return matchPath(pathname, { path: url, exact: true })
    })

    // If nothing found, return the first tab index to be selected by default
    return foundIndex === -1 ? 0 : foundIndex
  }, [pathname, selectedTabs])

  // Handles the selected page and available tab items
  useEffect(() => {
    const currentMenuItem = getCurrentMenuItem()
    setSelectedMenuItem(currentMenuItem.url)
    setSelectedTabs(getAvailableTabs(currentMenuItem))
  }, [pathname, getAvailableTabs, getCurrentMenuItem])

  // Handles the selected tab index
  useEffect(() => {
    if (selectedTabs.length > 0) {
      setSelectedTabIndex(findTabItemIndex())
    }
  }, [selectedTabs, findTabItemIndex])

  /**
   * Returns the menu item according to the current URL.
   *
   * If the URL does not match any of the items, returns the first menu item
   * as a fallback.
   */

  function handleMenuClick({ value }) {
    const destinationURL = generatePath(value, { ...urlsParams })
    setSelectedMenuItem(value)
    push(destinationURL)
  }

  function handleTabClick({ index, url }) {
    setSelectedTabIndex(index)
    push(
      generatePath(url, {
        ...urlsParams,
      }),
    )
  }

  return (
    <MainContainer>
      {Header && <Header />}

      <div className={style.container}>
        <ListMenu
          items={menuItems.map(({ name, url, ...props }) => ({
            text: name,
            value: url,
            ...props,
          }))}
          selectedItem={localSelectedMenuItem}
          onClick={handleMenuClick}
        />

        <div className={style.subPageContainer}>
          <Tabs
            items={selectedTabs}
            selectedTabIndex={selectedTabIndex}
            onClick={handleTabClick}
          />
        </div>
      </div>
    </MainContainer>
  )
}
