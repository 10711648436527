import React, { useState } from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks'

import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  Form,
  FormContext,
  InputField,
  CheckboxField,
  SubmitButton,
  validate,
} from '@labsavvyapp/ui-components'

import { Login } from '../../../graphql/user/mutations.js'
import { GetSession } from '../../../graphql/user/queries.js'
import { HOME, SIGN } from '../../../config/routes'
import { setCookie } from '../../../utils/auth'
import logo from '../../../assets/labsavvy-logo.svg'
import style from './SignInPage.module.css'

export default function SignInPage() {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const client = useApolloClient()

  const [login] = useMutation(Login, {
    update(cache, { data }) {
      const { tokens } = data.login
      setCookie(tokens.access)

      cache.writeQuery({
        query: GetSession,
        data: {
          session: {
            __typename: 'Session',
            token: tokens.access,
            isLoggedIn: true,
          },
        },
      })
    },
  })
  const [formErrors, setFormErrors] = useState({})

  const onLogin = async ({ email, password }) => {
    try {
      await login({ variables: { email, password } })
      client.resetStore()
      push(HOME)
    } catch (error) {
      if (error?.graphQLErrors?.[0]?.message) {
        const serverError = new Error(
          JSON.stringify({
            id: 'server_error',
            defaultMessage: error.graphQLErrors[0].message,
          }),
        )

        setFormErrors({
          email: serverError,
          password: serverError,
        })
      }
    }
  }

  const handleResetFormErrors = () => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({
        email: null,
        password: null,
      })
    }
  }

  return (
    <Form
      className={style.container}
      errors={formErrors}
      intl={{ formatMessage }}
      onSubmit={onLogin}
    >
      <FormContext.Consumer>
        {() => (
          <>
            <div className={style.header}>
              <img className={style.logo} src={logo} alt="logo" />
              <span className={style.appTitle}>
                administration
                <br />
                panel
              </span>
            </div>

            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <InputField
                    fluid
                    name="email"
                    placeholder="Email"
                    validate={validate.combine(
                      validate.notEmpty(),
                      validate.isEmail(),
                    )}
                    onChange={() => handleResetFormErrors()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <InputField
                    fluid
                    type="password"
                    name="password"
                    placeholder="Password"
                    validate={validate.combine(
                      validate.notEmpty(),
                      validate.isPasswordStrong(),
                    )}
                    onChange={() => handleResetFormErrors()}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <CheckboxField name="remember_me" label="Remember Me" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className={style.buttons}>
                    <SubmitButton>Sign in</SubmitButton>
                    <Link
                      className={style.forgotPasswordLink}
                      to={SIGN.recover}
                    >
                      Forgot password?
                    </Link>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </FormContext.Consumer>
    </Form>
  )
}
