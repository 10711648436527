import React, { useState } from 'react'

import { Button, SelectField } from '@labsavvyapp/ui-components'
import PlusIcon from '../../../components/Icons/PlusIcon'
import EditIcon from '../../../components/Icons/EditIcon'
import CrossIcon from '../../../components/Icons/CrossIcon'
import { Draggable } from 'react-beautiful-dnd'

import style from './PackageCategoryPanel.module.css'

const PackageCategoryPanel = ({
  packageCategories,
  selectedPanels,
  packageCategory,
  setShowTestGroupSelectionPanel,
  setCurrentPackageCategory,
  onUpdate,
  onDeleteCategory,
  children,
  index,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const filteredPackageCategories = packageCategories.filter(
    (packageCategoryItem) =>
      (packageCategoryItem &&
        !selectedPanels
          .map(
            (selected) => selected && selected.category && selected.category.id,
          )
          .includes(packageCategoryItem.id)) ||
      (packageCategory && packageCategoryItem.id === packageCategory.id),
  )

  if (packageCategory === null || packageCategory.id === '-1') {
    packageCategory = filteredPackageCategories[0]
    !isEditing && setIsEditing(true)
  }

  const packageCategoriesOptions = filteredPackageCategories.map(
    (packageCategory) => ({
      key: packageCategory?.id,
      value: packageCategory?.id,
      text: packageCategory?.name,
    }),
  )

  const handlePackageCategoriesChange = (value) => {
    onUpdate(value)
  }

  const handleOpenPanelsModal = () => {
    setCurrentPackageCategory(packageCategory?.id)
    setShowTestGroupSelectionPanel(true)
  }

  return (
    <Draggable
      key={packageCategory.id}
      draggableId={packageCategory.id}
      index={index}
      isDragDisabled={isEditing}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={style.packageCategoryPanelContainer}
          {...provided.draggableProps}
        >
          <div className={style.packageCategoryPanelHeader}>
            <div className={style.columnSelect}>
              {isEditing ? (
                <>
                  <SelectField
                    testId="dropdown-category"
                    className={style.packageCategorySelect}
                    name={packageCategory?.id}
                    onChange={handlePackageCategoriesChange}
                    options={packageCategoriesOptions}
                    value={packageCategory?.id}
                    loading={
                      !packageCategoriesOptions ||
                      packageCategoriesOptions.length === 0
                    }
                  />
                  <Button
                    className={style.saveButton}
                    data-test="button-save-category"
                    onClick={() => setIsEditing(false)}
                    variant={Button.variant.secondary}
                  >
                    Save
                  </Button>
                  <Button
                    className={style.deleteButton}
                    onClick={onDeleteCategory}
                    variant={Button.variant.basic}
                  >
                    <CrossIcon size="10" />{' '}
                    <span className={style.deleteText}>Delete category</span>
                  </Button>
                </>
              ) : (
                <h3
                  className={style.packageCategoryText}
                  {...provided.dragHandleProps}
                >
                  {packageCategory?.name}
                </h3>
              )}
            </div>
            {!isEditing && (
              <>
                <div className={style.columnPlusButton}>
                  <Button
                    variant={Button.variant.basic}
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon />
                  </Button>
                </div>
                <div className={style.columnPlusButton}>
                  <Button
                    className={style.plusButton}
                    onClick={handleOpenPanelsModal}
                  >
                    <PlusIcon size="10" />
                  </Button>
                </div>
              </>
            )}
          </div>
          <div>{children}</div>
        </div>
      )}
    </Draggable>
  )
}

export default PackageCategoryPanel
