import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { useIntl } from 'react-intl'

import {
  Button,
  Form,
  InputField,
  SubmitButton,
  validate,
} from '@labsavvyapp/ui-components'

import style from './RecoverPassword.module.css'
import logo from '../../../assets/labsavvy-logo.svg'
import { SIGN } from '../../../config/routes'

const PRE_RECOVER_EMAIL = gql`
  mutation preRecoverEmail($email: EmailAddress!) {
    preRecoverEmail(media: email, email: $email)
  }
`

/**
 * Recover password. Step 1.
 */
export default function RecoverPassword() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const [preRecoverEmail] = useMutation(PRE_RECOVER_EMAIL)
  const [formErrors, setFormErrors] = useState({})
  const [requestInProgress, setRequestInProgress] = useState(false)
  const INITIAL_FORM_DATA = { email: '' } // FIXME review this later.

  /**
   * Redirects to the next step.
   */
  const redirect = (email) => {
    setRequestInProgress(true)
    setTimeout(() => {
      push(`${SIGN.recoverConfirmToken}?email=${email}`)
    }, 2000)
  }

  /**
   * Form submission.
   */
  const onSubmit = async ({ email }) => {
    try {
      await preRecoverEmail({
        variables: {
          email,
        },
      })

      redirect(email)
    } catch (error) {
      const serverError = new Error(
        JSON.stringify({
          id: 'server_error',
          defaultMessage: error.graphQLErrors[0].message,
        }),
      )
      setFormErrors({
        email: serverError,
      })
    }
  }

  return (
    <Form
      className={style.root}
      errors={formErrors}
      initialFormData={INITIAL_FORM_DATA}
      intl={{ formatMessage }}
      onSubmit={onSubmit}
    >
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Column>
          <div className={style.head}>
            <img alt="logo" src={logo} className={style.logo} />
          </div>

          {!requestInProgress ? (
            <>
              <h3 className={style.title}>Forgot password?</h3>
              <p className={style.text}>
                Please enter the e-mail address you provided during
                registration. Further instructions will be sent to this address.
              </p>

              <InputField
                name="email"
                placeholder="Email"
                validate={validate.combine(
                  validate.notEmpty(),
                  validate.isEmail(),
                )}
              />

              <div className={style.actions}>
                <Button
                  data-test="button-cancel"
                  onClick={() => push(SIGN.in)}
                  variant={Button.variant.secondary}
                >
                  Cancel
                </Button>
                <SubmitButton>Continue</SubmitButton>
              </div>
            </>
          ) : (
            <p className={style.text}>Please check your e-mail account.</p>
          )}
        </Grid.Column>
      </Grid>
    </Form>
  )
}
