import React from 'react'
import classnames from 'classnames'

import sharedStyle from './TestGroupSelectionPanel.module.css'
import style from './GroupListItem.module.css'

export const GroupListItem = ({ item, selected, disabled, onClick }) => {
  return (
    <>
      {selected && (
        <div
          className={style.selectedPlaceholder}
          key="tmp-group-list-item"
        ></div>
      )}
      <div
        className={classnames(sharedStyle.listItem, {
          [sharedStyle.selected]: selected,
          [style.selected]: selected,
          [sharedStyle.disabled]: disabled,
        })}
        onClick={() => !item.selected && onClick(item)}
        data-test="group-list-item"
      >
        <span className={style.code}>{item.code}</span>
        <span>{item.name}</span>
      </div>
    </>
  )
}
