import gql from 'graphql-tag'

export const SaveMainPricingTest = gql`
  mutation ($input: MainPricingTestInput!) {
    saveMainPricingTest(input: $input) {
      test_code
      test_name
      provider_price
      ls_price
      standard_retail_price
    }
  }
`

export const SavePricingFormula = gql`
  mutation ($input: PricingFormulaInput!) {
    savePricingFormula(input: $input) {
      data {
        left
        right
        value
      }
    }
  }
`
