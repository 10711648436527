import gql from 'graphql-tag'

export const ListPackageCategories = gql`
  query ListPackageCategories(
    $limit: PositiveInt = 50
    $page: PositiveInt = 1
    $filter: PackageCategoryFilter
    $sort: PackageCategorySort
  ) {
    listPackageCategories(
      limit: $limit
      page: $page
      filter: $filter
      sort: $sort
    ) {
      package_categories {
        _id
        name
        icon {
          url
        }
        completed
      }
      page
      pages
      total
    }
  }
`

export const GetPackageCategory = gql`
  query GetPackageCategory($packageCategoryId: MongoID!) {
    getPackageCategory(packageCategoryId: $packageCategoryId) {
      _id
      name
      icon {
        url
      }
      subtitle
      completed
      summary
      details
    }
  }
`
