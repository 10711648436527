import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'

export default function EditIcon({
  className,
  color = '#d76acd',
  onClick,
  size = 18,
  strokeWidth = '1.5',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 19"
      className={classnames(style.icon, className)}
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          strokeWidth={strokeWidth}
          d="M10.934 3.967l2.636 2.634M4.607 15.69L15.969 4.258l-2.833-2.832L1.774 12.858v2.832h2.833zm3.04 0h8.137"
          transform="translate(0 1)"
        />
        <path d="M0 0H18V18H0z" transform="translate(0 1)" />
      </g>
    </svg>
  )
}
