import React from 'react'
import { Switch, Route } from 'react-router'

import MainLayout from '../components/MainLayout/MainLayout'
import { PACKAGES } from '../config/routes'
import PackagesPage from '../pages/Packages/PackagesPage/PackagesPage'
import PackageFormPage from '../pages/Packages/PackageFormPage/PackageFormPage'

export default function PackagesRoutes({ logged, user }) {
  return (
    <Route
      path={PACKAGES.base}
      render={({ match }) => (
        <MainLayout noPadding logged={logged} user={user}>
          <Switch>
            <Route exact path={match.path} component={PackagesPage} />

            <Route exact path={PACKAGES.new} component={PackageFormPage} />

            <Route exact path={PACKAGES.manage} component={PackageFormPage} />
          </Switch>
        </MainLayout>
      )}
    />
  )
}
