import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { useIntl } from 'react-intl'

import {
  Form,
  SubmitButton,
  LogoImageUploadField,
  ColorField,
  useSavingModals,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import sharedStyles from '../../../../styles/shared.module.css'
import style from './BrandingTab.module.css'
import { GetPartnerProjectBranding } from '../../../../graphql/partner/queries.js'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations.js'
import { UploadImage } from '../../../../graphql/files/mutations.js'

export default function BrandingTab() {
  const { formatMessage } = useIntl()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { partnerId, projectId } = useParams()

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving your data, please wait...",
    savedMessage: 'Your data has been saved.',
    callback: async (formData) => {
      const { branding, logo_id, name } = formData
      await updatePartnerProject({
        variables: {
          partnerId,
          projectId,
          data: {
            name,
            branding,
            logo_id,
          },
        },
      })
    },
  })

  // Queries
  const { data } = useQuery(GetPartnerProjectBranding, {
    variables: { partnerId, projectId },
  })

  const partnerProjectLogo = data?.getPartnerProject?.logo?.url

  const [upload, uploadData] = useMutation(UploadImage)

  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  const [updatePartnerProject] = useMutation(UpdatePartnerProject)

  return (
    <>
      <Form
        initialFormData={data && data.getPartnerProject}
        onSubmit={showModals}
        intl={{ formatMessage }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <LogoImageUploadField
                image={partnerProjectLogo}
                label="Logo"
                name="logo_id"
                showLoader={uploadData.loading}
                uploadFn={uploadFile}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <hr />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <div className={style.colorFields}>
                <ColorField
                  className={style.colorField}
                  label="Sidebar Color"
                  name="branding.sidebar_color"
                />
                <ColorField
                  className={style.colorField}
                  label="Icon selected"
                  name="branding.icon_selected"
                />
                <ColorField
                  className={style.colorField}
                  label="Button Background"
                  name="branding.button_background"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <div className={style.colorFields}>
                <ColorField
                  className={style.colorField}
                  label="Button Text"
                  name="branding.button_text"
                />
                <ColorField
                  className={style.colorField}
                  label="Text Links"
                  name="branding.text_link"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
    </>
  )
}
