import React from 'react'

import { Grid } from 'semantic-ui-react'
import {
  Form,
  InputField,
  SubmitButton,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import { useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'

import sharedStyles from '../../../../styles/shared.module.css'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations.js'
import { GetPartnerProjectPhysician } from '../../../../graphql/partner/queries.js'

export default function AppovingPhisicianTab() {
  const { partnerId, projectId } = useParams()

  const { data } = useQuery(GetPartnerProjectPhysician, {
    variables: {
      partnerId,
      projectId,
    },
    skip: !projectId,
  })

  // Update project
  const [updatePartnerProject] = useMutation(UpdatePartnerProject, {
    refetchQueries: [
      {
        query: GetPartnerProjectPhysician,
        variables: {
          partnerId,
          projectId,
        },
      },
    ],
  })

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the project, please wait...",
    savedMessage: 'The project has been saved.',
    callback: async (formData) => {
      updatePartnerProject({
        variables: { partnerId, projectId, data: formData },
      })
    },
  })

  return (
    <>
      <Form
        initialFormData={data && data.getPartnerProject}
        onSubmit={showModals}
      >
        <Grid>
          <Grid.Row computer={8} tablet={16}>
            <Grid.Column computer={4} tablet={16}>
              <InputField name="physician.npi" label="NPI Number" />
            </Grid.Column>
            <Grid.Column computer={4} tablet={16}>
              <InputField name="physician.name.last" label="Last Name" />
            </Grid.Column>
            <Grid.Column computer={4} tablet={16}>
              <InputField name="physician.name.first" label="First Name" />
            </Grid.Column>
            <Grid.Column computer={4} tablet={16}>
              <InputField name="physician.name.prefix" label="Title" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SubmitButton className={sharedStyles.marginTop10}>
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
