import gql from 'graphql-tag'

export const AddPartnerProjectPackage = gql`
  mutation AddPartnerProjectPackage(
    $partnerId: MongoID!
    $projectId: MongoID!
    $packageId: MongoID!
    $providerId: MongoID!
    $name: String
    $commission: NonNegativeInt
  ) {
    addPartnerProjectPackage(
      partner_id: $partnerId
      project_id: $projectId
      package_id: $packageId
      provider_id: $providerId
      name: $name
      commission: $commission
    ) {
      _id
    }
  }
`

export const CreatePackage = gql`
  mutation CreatePackage($data: PackageInput!) {
    createPackage(data: $data) {
      _id
    }
  }
`

export const UpdatePackage = gql`
  mutation UpdatePackage($id: MongoID!, $data: PackageInput!) {
    updatePackage(id: $id, data: $data) {
      _id
    }
  }
`
