import React from 'react'

import classnames from 'classnames'
import { ListRow, NameInitials, ProfilePhoto } from '@labsavvyapp/ui-components'
import { useHistory, generatePath } from 'react-router'
import { utc } from 'moment'

import sharedStyle from '../../../../styles/shared.module.css'
import { TEAM_MEMBERS } from '../../../../config/routes'
import style from './TeamMembersListRow.module.css'

// FIXME: We have some checks, like to check if the first name exists.
// These needs to be removed once the DB is fixed.
export default function TeamMembersListRow({ items }) {
  const { push } = useHistory()

  return items.map((item) => (
    <ListRow
      key={item._id}
      className={classnames(sharedStyle.listRow, style.row)}
      onClick={() => push(generatePath(TEAM_MEMBERS.manage, { id: item._id }))}
    >
      <div className={classnames(style.column, style.imageNameColumn)}>
        {item.profile_photo ? (
          <ProfilePhoto
            className={style.memberImage}
            size={35}
            url={item.profile_photo.url}
          />
        ) : item.name && item.name.first && item.name.last ? (
          <NameInitials
            firstName={item.name.first}
            lastName={item.name.last}
            size={34}
            className={style.memberImage}
          />
        ) : (
          ''
        )}

        {item.name ? item.name.display : ''}
      </div>
      <div className={style.column}>{item.email}</div>
      <div className={style.column}>{item.role && item.role.name}</div>
      <div className={style.column}>
        {utc(item.joined).format('MM/DD/YYYY')}
      </div>
      <div className={style.column}>{item.status || 'Active'}</div>
    </ListRow>
  ))
}
