import gql from 'graphql-tag'

export const ListTestReferenceCategories = gql`
  query ListTestReferenceCategories(
    $filter: TestReferenceCategoryFilter
    $limit: PositiveInt = 50
    $page: NonNegativeInt = 1
  ) {
    listTestReferenceCategories(filter: $filter, limit: $limit, page: $page) {
      test_reference_categories {
        _id
        name
      }
      page
      pages
      total
    }
  }
`
