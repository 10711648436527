import React, { useState, useEffect } from 'react'
import {
  List,
  ListRow,
  Button,
  Checkbox,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import { GetPartnerProjectNotifications } from '../../../../graphql/partner/queries.js'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations.js'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import style from './Notifications.module.css'
import { Input } from 'semantic-ui-react'

function Notifications() {
  const { partnerId, projectId } = useParams()
  const [formDirty, setFormDirty] = useState(false)
  const { data, refetch } = useQuery(GetPartnerProjectNotifications, {
    variables: { partnerId, projectId },
    fetchPolicy: 'no-cache',
  })
  const [updatePartnerProject] = useMutation(UpdatePartnerProject)
  const [notifications, setNotifications] = useState([])

  const [notificationEmail, setNotificationEmail] = useState()
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're updating your notification settings",
    savedMessage: 'Notifications updated successfuly!',
    callback: async () => {
      await updatePartnerProject({
        variables: {
          partnerId,
          projectId,
          data: {
            notifications: notifications
              .filter((notification) => notification.enabled)
              .map((item) => {
                return item._id
              }),
            notification_email: notificationEmail,
          },
        },
      })
      setFormDirty(false)
      refetch()
    },
    errorMessage: 'Something went wrong, please try again later!',
  })

  useEffect(() => {
    setNotifications(
      data?.getPartnerProject?.notifications?.map((item) => {
        return {
          _id: item._id,
          name: item.name,
          description: item.description,
          enabled: item.enabled === 'true' ? true : false,
        }
      }),
    )
    setNotificationEmail(data?.getPartnerProject?.notification_email)
  }, [data])

  const toggleNotifications = async (item) => {
    const updatedItems = [...notifications]
    updatedItems.forEach((element, index) => {
      if (element._id === item.value) {
        updatedItems[index].enabled = !updatedItems[index].enabled
      }
    })
    setNotifications(updatedItems)
    if (!formDirty) {
      setFormDirty(true)
    }
  }

  const handleCancel = () => {
    setNotifications(
      data?.getPartnerProject?.notifications?.map((item) => {
        return {
          _id: item._id,
          name: item.name,
          description: item.description,
          enabled: item.enabled === 'true' ? true : false,
        }
      }),
    )
    setFormDirty(false)
  }

  const notificationEmailChange = async (item) => {
    if (!formDirty) {
      setFormDirty(true)
    }
  }

  return (
    <>
      <List>
        <div style={{ width: '50%', paddingBottom: 50 }}>
          <label style={{ fontSize: 13, paddingBottom: 5 }}>
            White Label Email Notification
          </label>
          <Input
            value={notificationEmail || ''}
            onChange={(event) => setNotificationEmail(event.target.value)}
            onKeyPress={notificationEmailChange}
            fluid
            title="Defaults to 'LabSavvy Team <team@labsavvy.com>'"
          />
          <span className={style.breadcrumbsText}>
            {' e.g. LabSavvy Team <team@labsavvy.com>'}
          </span>
        </div>
        {notifications?.map((item) => (
          <ListRow
            key={item._id}
            onClick={() => toggleNotifications({ value: item._id })}
          >
            {
              <Checkbox
                className={style.checkboxLabel}
                backgroundColor={'var(--green)'}
                type="checkbox"
                onChange={toggleNotifications}
                checked={item.enabled}
                value={item._id}
                label={item.name}
              ></Checkbox>
            }
          </ListRow>
        ))}
        {
          <div className={style.buttonsContainer}>
            <Button
              className={style.cancelButton}
              onClick={handleCancel}
              size="small"
              disabled={!formDirty}
            >
              Cancel
            </Button>
            <Button
              className={style.saveButton}
              onClick={showModals}
              size="small"
              disabled={!formDirty}
            >
              Save Changes
            </Button>
          </div>
        }
      </List>
      {/* Modals */}
      {modals}
    </>
  )
}

export default Notifications
