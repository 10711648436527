import React from 'react'
import { Helmet } from 'react-helmet-async'

import { Icon } from 'semantic-ui-react'

import style from './Dashboard.module.css'

export default function DashboardPage() {
  return (
    <div className={style.container}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Icon name="settings" size="huge" />

      <h1 className={style.title}>Dashboard</h1>
      <h2 className={style.subtitle}>
        Welcome to the LabSavvy Platform Admin Panel
      </h2>
    </div>
  )
}
