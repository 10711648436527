import gql from 'graphql-tag'

export const CreatePackageCategory = gql`
  mutation CreatePackageCategory($packageCategory: PackageCategoryInput!) {
    createPackageCategory(packageCategory: $packageCategory) {
      _id
    }
  }
`

export const UpdatePackageCategory = gql`
  mutation UpdatePackageCategory(
    $packageCategoryId: MongoID!
    $packageCategory: PackageCategoryInput!
  ) {
    updatePackageCategory(
      packageCategoryId: $packageCategoryId
      packageCategory: $packageCategory
    ) {
      _id
    }
  }
`

export const DeletePackageCategory = gql`
  mutation DeletePackageCategory($packageCategoryId: MongoID!) {
    deletePackageCategory(packageCategoryId: $packageCategoryId)
  }
`
