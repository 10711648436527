import gql from 'graphql-tag'

export const GenerateMainPricings = gql`
  mutation GenerateMainPricings($input: MongoID) {
    generateMainPricings(input: $input)
  }
`

export const GeneratePartnerPricings = gql`
  mutation GeneratePartnerPricings($partnerId: MongoID) {
    generatePartnerPricings(partner_id: $partnerId)
  }
`

export const UpdateConfig = gql`
  mutation UpdateConfig($input: ConfigsInput!) {
    updateConfig(input: $input) {
      _id
      name
      description
      value
      value2
      value3
    }
  }
`

export const ImportMainPricing = gql`
  mutation ImportMainPricing($providerId: MongoID!, $input: [PricingImport!]!) {
    importMainPricing(provider_id: $providerId, input: $input) {
      status
      message
    }
  }
`
