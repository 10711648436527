import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { Search, Button } from '@labsavvyapp/ui-components'
import { camelizeKeys } from 'humps'

import { ListPartnerTeamMembers } from '../../../../graphql/partner/queries.js'
import TeamMembersList from './TeamMembersList/TeamMembersList'
import { updateURLParameter } from '../../../../utils/urls'
import { SORT_BY_OPTIONS } from './constants'
import style from './TeamMembersTab.module.css'
import { PARTNERS } from '../../../../config/routes'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = { first: SORT_BY_OPTIONS.nameAZ.value }
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = { first: SORT_BY_OPTIONS.nameZA.value }
      break
    case SORT_BY_OPTIONS.roleAZ.key:
      variables.sort.role = { priority: SORT_BY_OPTIONS.roleAZ.value }
      break
    case SORT_BY_OPTIONS.roleZA.key:
      variables.sort.role = { priority: SORT_BY_OPTIONS.roleZA.value }
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function TeamMembersTab() {
  const { push } = useHistory()
  const { partnerId, section } = useParams()

  const query = new URLSearchParams(window.location.search)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')
  const {
    data: teamMemberData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(ListPartnerTeamMembers, {
    variables: { partnerId, ...getQueryVariables(sortBy, search) },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      partnerId,
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, partnerId, refetch])

  /**
   * Constructs the URL by replacing the partnerId and section with the
   * current tab, and appends the search and sort parameters.
   */
  function navigateToURL(params) {
    const baseURL = PARTNERS.admin.section
      .replace(':partnerId', partnerId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    navigateToURL(urlParams)
    setSearch(value)
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div>
          <span className={style.dropdownLabel}>Sort by:</span>
          <Dropdown
            selection
            className={style.dropdown}
            value={sortBy}
            options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
              text: SORT_BY_OPTIONS[option].text,
              value: SORT_BY_OPTIONS[option].key,
              key: SORT_BY_OPTIONS[option].key,
            }))}
            onChange={handleSortChange}
            data-test="dropdown-sort"
          />
        </div>

        <Search
          className={style.search}
          placeholder="Search by Name"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button
          data-test="button-invite-team-member"
          onClick={() => push('team-members/invite')}
          size={Button.size.small}
        >
          Invite Team Member
        </Button>
      </div>

      <TeamMembersList
        data={camelizeKeys(teamMemberData)}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
      />
    </div>
  )
}
