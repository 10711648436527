import gql from 'graphql-tag'

export const InviteAdminTeamMember = gql`
  mutation InviteAdminTeamMember($user: AdminTeamMemberInput!) {
    inviteAdminTeamMember(user: $user) {
      _id
    }
  }
`

export const UpdateAdminTeamMember = gql`
  mutation UpdateAdminTeamMember($id: MongoID!, $user: AdminTeamMemberInput!) {
    updateAdminTeamMember(id: $id, user: $user) {
      _id
    }
  }
`
