import gql from 'graphql-tag'

export const ListPartnerProjectProviders = gql`
  query ListPartnerProjectProviders($projectId: MongoID!) {
    listPartnerProjectProviders(project_id: $projectId) {
      account_number
      provider {
        _id
        created_at
        updated_at
        name
        short_name
        key
      }
    }
  }
`
