import React from 'react'

import classnames from 'classnames'
import { ListRow } from '@labsavvyapp/ui-components'
import { useHistory } from 'react-router'

import sharedStyle from '../../../../styles/shared.module.css'
import { PACKAGE_CATEGORIES } from '../../../../config/routes'
import style from './PackageCategoriesListRow.module.css'
import checkMark from '../../../../assets/icons/checkmark.svg'

export default function PackageCategoriesListRow({ items }) {
  const { push } = useHistory()

  return items.map((item) => (
    <ListRow
      data-test="package-categories-list-row"
      key={item.id}
      className={classnames(sharedStyle.listRow, style.row)}
      onClick={() => push(`${PACKAGE_CATEGORIES.base}/${item.id}`)}
    >
      <div className={style.iconColumn}>
        {item.icon ? (
          <img src={item.icon.url} className={style.iconImg} alt="logo" />
        ) : (
          <div className={style.iconImg}></div>
        )}
      </div>
      <div className={style.column}>{item.name}</div>
      <div className={style.column}>
        {item.completed && <img src={checkMark} alt="logo" />}
      </div>
    </ListRow>
  ))
}
