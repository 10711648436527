export const SORT_BY_OPTIONS = Object.freeze({
  by_test_code: {
    value: 'desc',
    text: 'by Test Code',
    key: 'by_test_code',
  },
  by_test_name: {
    value: 'desc',
    text: 'by Test Name',
    key: 'by_test_name',
  },
  by_partner_pricing: {
    value: 'desc',
    text: 'by Partner Pricing',
    key: 'by_partner_pricing',
  },
  by_main_pricing: {
    value: 'desc',
    text: 'by Main Pricing',
    key: 'by_main_pricing',
  },
  // by_override: {
  //   value: 'desc',
  //   text: 'by Override',
  //   key: 'by_override',
  // },
  // by_price: {
  //   value: 'desc',
  //   text: 'by Price',
  //   key: 'by_price',
  // },
})

export const SEARCH_BY_OPTIONS = Object.freeze({
  by_test_code: {
    value: 'test_code',
    text: 'Test Code',
    key: 'by_test_code',
  },
  by_test_name: {
    value: 'test_name',
    text: 'Test Name',
    key: 'by_test_name',
  },
})
