import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router'
import classnames from 'classnames'
import { Button, Search } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../styles/shared.module.css'
import style from './SettingsPage.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import MaintenancePage from '../MaintenancePage/MaintenancePage'

export default function SettingMenuItem({ data, handleMenuSelect, index }) {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const handleMenuSelection = () => {
    handleMenuSelect(index)
    push(data.url)
  }

  const getActiveState = (data) => {
    if (data?.subcomponents && data.subcomponents.includes(pathname)) {
      return style.settingsCategoryActive
    }
    if (data.active) {
      return style.settingsCategoryActive
    } else {
      return style.settingsCategory
    }
  }

  return (
    <div
      className={getActiveState(data)}
      onClick={() => handleMenuSelection(index)}
    >
      {data.name}
    </div>
  )
}
