import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { InfiniteScrollListProvider } from '@labsavvyapp/ui-components'
import { useQuery, useApolloClient } from '@apollo/react-hooks'

import {
  PROFILE,
  CATEGORIES,
  HOME,
  SIGN,
  PARTNERS,
  TEAM_MEMBERS,
  MAIN_PRICING,
  PACKAGES,
  PACKAGE_CATEGORIES,
  SETTINGS,
} from '../config/routes'
import withMainLayout from '../components/MainLayout/withMainLayout'
import { GetMe } from '../graphql/user/queries.js'
import AuthenticationRoutes from './AuthenticationRoutes'
import Dashboard from '../pages/Dashboard/Dashboard'
import ProfileRoutes from '../routes/ProfileRoutes'
import PartnersListPage from '../pages/Partners/List/ListPage'
import PartnerRoutes from './PartnerRoutes'
import TeamMembersRoutes from './TeamMembersRoutes'
import MainPricingRoutes from './MainPricingRoutes'
import CategoriesPage from '../pages/Categories/CategoriesPage'
import PackagesRoutes from './PackagesRoutes'
import SettingsRoutes from './SettingsRoutes'
import PackageCategoriesRoutes from './PackageCategoriesRoutes'
import Error404Page from '../pages/Errors/Error404Page'
import { isAuthenticated, removeCookies } from '../utils/auth'
import { GetSession } from '../graphql/user/queries.js'

export default function Routes() {
  const client = useApolloClient()

  const { data: sessionData } = useQuery(GetSession)
  const session = sessionData && sessionData.session
  const isLoggedIn = session ? session.isLoggedIn : isAuthenticated()

  const { data: userData, error } = useQuery(GetMe, { skip: !isLoggedIn })
  const user = userData && userData.getMe

  const hasAuthenticationError =
    error &&
    error.graphQLErrors.find(
      ({ message }) => message === 'Unauthorized Access.',
    )

  if (hasAuthenticationError) {
    removeCookies()
    client.resetStore()
  }

  return (
    <Switch>
      <AuthenticationRoutes path={SIGN.base} />

      {isLoggedIn && !hasAuthenticationError ? (
        <InfiniteScrollListProvider>
          <Switch>
            <Route
              exact
              path={HOME}
              render={() =>
                withMainLayout(Dashboard, { logged: isLoggedIn, user })
              }
            />
            <Route
              path={PROFILE.base}
              render={() =>
                withMainLayout(ProfileRoutes, {
                  noPadding: true,
                  logged: isLoggedIn,
                  user,
                })
              }
            />
            <Route
              exact
              path={CATEGORIES.base}
              render={() =>
                withMainLayout(CategoriesPage, {
                  noPadding: true,
                  logged: isLoggedIn,
                  user,
                })
              }
            />
            <Route
              exact
              path={PARTNERS.list}
              render={() =>
                withMainLayout(PartnersListPage, {
                  noPadding: true,
                  logged: isLoggedIn,
                  user,
                })
              }
            />
            <Route path={PARTNERS.base}>
              <PartnerRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route path={MAIN_PRICING.base}>
              <MainPricingRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route path={TEAM_MEMBERS.base}>
              <TeamMembersRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route path={SETTINGS.base}>
              <SettingsRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route path={PACKAGES.base}>
              <PackagesRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route path={PACKAGE_CATEGORIES.base}>
              <PackageCategoriesRoutes logged={isLoggedIn} user={user} />
            </Route>
            <Route component={Error404Page} />
          </Switch>
        </InfiniteScrollListProvider>
      ) : (
        <Redirect to={SIGN.in} />
      )}
    </Switch>
  )
}
