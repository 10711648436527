import React from 'react'

import { List, ListRow, Button } from '@labsavvyapp/ui-components'
import classnames from 'classnames'

import sharedStyles from '../../../../styles/shared.module.css'
import style from './InvoicesTab.module.css'

const invoicesDataMock = [
  {
    id: 'invoice-1',
    date: 'June 5, 2019',
    plan: 'Strategic Partner Plan',
    price: '$3,160.00',
  },
  {
    id: 'invoice-2',
    date: 'May 5, 2019',
    plan: 'Strategic Partner Plan',
    price: '$2,428.00',
  },
  {
    id: 'invoice-3',
    date: 'April 5, 2019',
    plan: 'Strategic Partner Plan',
    price: '$1,860.00',
  },
]

export default function InvoicesTab() {
  return (
    <div className={style.container}>
      <List>
        {invoicesDataMock.map((invoice) => (
          <ListRow key={invoice.id} className={style.row}>
            <div
              className={classnames(
                sharedStyles.marginRight20,
                style.dateColumn,
              )}
            >
              {invoice.date}
            </div>
            <div className={sharedStyles.marginRight20}>{invoice.plan}</div>
            <div className={sharedStyles.marginRight20}>{invoice.price}</div>
            <div>
              <Button
                className={classnames(
                  sharedStyles.linkButton,
                  style.viewInvoiceButton,
                )}
                variant={Button.variant.basic}
              >
                View Invoice
              </Button>
            </div>
          </ListRow>
        ))}
      </List>
    </div>
  )
}
