import React from 'react'
import * as Sentry from '@sentry/browser'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ApolloProvider } from '@apollo/react-hooks'
import { HelmetProvider } from 'react-helmet-async'

import { createClient } from './graphql/graphqlClient'
import { defaultLocale, translations } from './locales'
import HTMLHeaders from './HTMLHeaders'
import Routes from './routes/Routes'
import IdleTimerContainer from './utils/idle/IdleTimeContainer'

if (process.env.REACT_APP_USE_ERROR_TRACKING === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  })
}

function App() {
  return (
    <ApolloProvider client={createClient()}>
      <BrowserRouter>
        <IntlProvider
          locale={defaultLocale}
          messages={translations[defaultLocale]}
        >
          <HelmetProvider>
            <HTMLHeaders />
            <Routes />
            <IdleTimerContainer />
          </HelmetProvider>
        </IntlProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
