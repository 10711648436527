import React from 'react'
import { matchPath } from 'react-router'

import classnames from 'classnames'
import { Header, HeaderDropdown, Sidebar } from '@labsavvyapp/ui-components'
import { useHistory, useLocation } from 'react-router'

import {
  PROFILE,
  CATEGORIES,
  PARTNERS,
  SIGN,
  HOME,
  TEAM_MEMBERS,
  MAIN_PRICING,
  PACKAGES,
  PACKAGE_CATEGORIES,
  SETTINGS,
} from '../../config/routes'

import logo from '../../assets/labsavvy-logo.svg'
import homeIcon from '../../assets/icons/dashboard-icon.svg'
import partnersIcon from '../../assets/icons/organizations-icon.svg'
import packagesIcon from '../../assets/icons/cube-icon.svg'
import teamMembersIcon from '../../assets/icons/person-icon.svg'
import categoriesIcon from '../../assets/icons/box-icon.svg'
import packageCategoriesIcon from '../../assets/icons/categories-icon.svg'
import dollarIcon from '../../assets/icons/dollar-icon.svg'
import settingsIcon from '../../assets/icons/settings-icon.svg'

import style from './MainLayout.module.css'

function sidebarItems(pathname) {
  return [
    {
      title: 'Dashboard',
      icon: homeIcon,
      url: HOME,
      active: matchPath(pathname, {
        path: HOME,
        exact: true,
      }),
    },
    {
      title: 'Partners',
      icon: partnersIcon,
      url: PARTNERS.list,
      active:
        matchPath(pathname, {
          path: PARTNERS.list,
          exact: true,
        }) ||
        matchPath(pathname, {
          path: PARTNERS.base,
        }),
    },
    {
      title: 'Packages',
      icon: packagesIcon,
      url: PACKAGES.base,
      active: matchPath(pathname, {
        path: PACKAGES.base,
      }),
    },
    {
      title: 'Package Categories',
      icon: packageCategoriesIcon,
      url: PACKAGE_CATEGORIES.base,
      active: matchPath(pathname, {
        path: PACKAGE_CATEGORIES.base,
      }),
    },
    {
      title: 'Main Pricing',
      icon: dollarIcon,
      url: MAIN_PRICING.base,
      active: matchPath(pathname, {
        path: MAIN_PRICING.base,
      }),
    },
    {
      title: 'Team Members',
      icon: teamMembersIcon,
      url: TEAM_MEMBERS.base,
      active: matchPath(pathname, {
        path: TEAM_MEMBERS.base,
      }),
    },
    {
      title: 'Categories',
      icon: categoriesIcon,
      url: CATEGORIES.base,
      active: matchPath(pathname, {
        path: CATEGORIES.base,
      }),
    },
    // {
    //   title: 'Settings',
    //   icon: settingsIcon,
    //   url: SETTINGS.base,
    //   active: matchPath(pathname, {
    //     path: SETTINGS.base,
    //   }),
    // },
  ]
}

const headerDropdownItems = [
  {
    name: 'User Profile',
    url: PROFILE.base,
    showWhenLoggedIn: true,
  },
  {
    name: 'Sign in',
    url: SIGN.in,
    showWhenLoggedIn: false,
  },
  {
    name: 'Sign Out',
    url: SIGN.out,
    showWhenLoggedIn: true,
  },
]

export default function MainLayout({ children, user, logged, noPadding }) {
  const { push } = useHistory()
  const { pathname } = useLocation()

  function handleLinkClick({ url }) {
    push(url)
  }

  return (
    <div className={style.container}>
      <Header
        className={style.header}
        logo={{ image: logo, url: HOME }}
        onLinkClick={() => handleLinkClick({ url: HOME })}
      >
        <HeaderDropdown
          logged={logged}
          me={user}
          dropdownItems={headerDropdownItems}
          onLinkClick={handleLinkClick}
        />
      </Header>

      <div className={style.contentHolder}>
        <Sidebar
          items={sidebarItems(pathname)}
          onItemClick={(url) => handleLinkClick({ url })}
        />
        <div
          className={classnames(style.content, {
            [style.contentNoPadding]: noPadding,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
