import gql from 'graphql-tag'

export const CreatePartner = gql`
  mutation CreatePartner($data: PartnerInput!) {
    createPartner(data: $data) {
      _id
    }
  }
`

export const CreatePartnerProject = gql`
  mutation CreatePartnerProject($partnerId: MongoID!, $data: ProjectInput!) {
    createPartnerProject(partner_id: $partnerId, data: $data) {
      _id
    }
  }
`

export const DeletePartnerTeamMember = gql`
  mutation DeletePartnerTeamMember(
    $partner_id: MongoID!
    $team_member_id: MongoID!
  ) {
    deletePartnerTeamMember(
      partner_id: $partner_id
      team_member_id: $team_member_id
    ) {
      _id
    }
  }
`

export const EditPartnerTeamMember = gql`
  mutation EditPartnerTeamMember(
    $partner_id: MongoID!
    $team_member_id: MongoID!
    $data: PartnerTeamMemberInput!
  ) {
    editPartnerTeamMember(
      partner_id: $partner_id
      team_member_id: $team_member_id
      data: $data
    ) {
      _id
    }
  }
`

export const InvitePartnerTeamMember = gql`
  mutation InvitePartnerTeamMember(
    $id: MongoID!
    $data: PartnerTeamMemberInput!
  ) {
    invitePartnerTeamMember(id: $id, data: $data) {
      _id
    }
  }
`

export const AddUserToPartner = gql`
  mutation AddUserToPartner(
    $userId: MongoID!
    $partnerId: MongoID!
    $role: String!
  ) {
    addUserToPartner(user_id: $userId, partner_id: $partnerId, role: $role) {
      _id
    }
  }
`

export const UpdatePartner = gql`
  mutation UpdatePartner($id: MongoID!, $data: PartnerInput!) {
    updatePartner(id: $id, data: $data) {
      _id
    }
  }
`

export const UpdatePartnerProject = gql`
  mutation UpdatePartnerProject(
    $partnerId: MongoID!
    $projectId: MongoID!
    $data: ProjectInput!
  ) {
    updatePartnerProject(
      partner_id: $partnerId
      project_id: $projectId
      data: $data
    ) {
      _id
    }
  }
`

export const DeletePartnerProject = gql`
  mutation DeletePartnerProject($partnerId: MongoID!, $projectId: MongoID!) {
    deletePartnerProject(partner_id: $partnerId, project_id: $projectId) {
      _id
    }
  }
`

export const DeletePartnerProjectTeamMember = gql`
  mutation DeletePartnerProjectTeamMember(
    $partnerId: MongoID!
    $projectId: MongoID!
    $userId: MongoID!
  ) {
    deletePartnerProjectTeamMember(
      partner_id: $partnerId
      project_id: $projectId
      user_id: $userId
    ) {
      _id
    }
  }
`

export const DeletePartnerProjectPackage = gql`
  mutation DeletePartnerProjectPackage(
    $partnerId: MongoID!
    $projectId: MongoID!
    $packageId: MongoID!
  ) {
    deletePartnerProjectPackage(
      partner_id: $partnerId
      project_id: $projectId
      package_id: $packageId
    )
  }
`

export const InvitePartnerProjectTeamMember = gql`
  mutation InvitePartnerProjectTeamMember(
    $partnerId: MongoID!
    $projectId: MongoID!
    $userId: MongoID!
  ) {
    invitePartnerProjectTeamMember(
      partner_id: $partnerId
      project_id: $projectId
      user_id: $userId
    ) {
      _id
    }
  }
`

export const ProcessPartnerMiscPricing = gql`
  mutation ProcessPartnerMiscPricing(
    $partnerId: MongoID!
    $providerId: MongoID!
    $data: MiscPricingInput!
  ) {
    processPartnerMiscPricing(
      partner_id: $partnerId
      provider_id: $providerId
      data: $data
    ) {
      _id
    }
  }
`

export const AddMiscPricingType = gql`
  mutation AddMiscPricingType(
    $partnerId: MongoID!
    $providerId: MongoID!
    $data: MiscPricingInput!
  ) {
    addMiscPricingType(
      partner_id: $partnerId
      provider_id: $providerId
      data: $data
    ) {
      _id
    }
  }
`
