export const SORT_BY_OPTIONS = Object.freeze({
  by_test_code: {
    value: 'desc',
    text: 'by Misc Code',
    key: 'by_test_code',
  },
  by_test_name: {
    value: 'desc',
    text: 'by Misc Name',
    key: 'by_test_name',
  },
  by_override: {
    value: 'desc',
    text: 'by Override',
    key: 'by_override',
  },
  by_price: {
    value: 'desc',
    text: 'by Price',
    key: 'by_price',
  },
})
