import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import { Input } from 'semantic-ui-react'
import classnames from 'classnames'

import {
  Button,
  Form,
  ListRow,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from 'react-apollo'
import sharedStyle from '../../../styles/shared.module.css'

// Styles
import style from './DataManagementPage.module.css'

// Components
import DataManagementRow from './DataManagementRow'

// Utilities
import useWindowDimensions from '../../../utils/useWindowDimensions'
import { read, utils } from 'xlsx'

// Queries and Mutations
import { ListProviders } from '../../../graphql/providers/queries'
import { ImportMainPricing } from '../../../graphql/settings/mutations'

export default function DataManagementPage() {
  const { push } = useHistory()
  const { width } = useWindowDimensions()
  const inputFile = useRef(null)

  // Query handlers
  const { data: { listProviders: providers } = {} } = useQuery(ListProviders, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  // Mutation handlers
  const [importMainPricing, { data: importMainPricingStatus }] =
    useMutation(ImportMainPricing)

  // Modals
  const [modals, { showModals: startImportMainPricing }] = useSavingModals({
    savingMessage:
      "We're importing main pricing data for this partner. Please wait...",
    savedMessage: importMainPricingStatus?.importMainPricing?.message,
    callback: async () => {
      await importMainPricing({
        variables: {
          providerId: selectedProvider._id,
          input: uploadFileData,
        },
      })
    },
    onError: (error) => error,
  })

  // Handle Modal success message

  // Menu Items with no data dependencies
  const [maintenanceItems, setMaintenanceItems] = useState([])

  // Rerender if data dependecies are loaded
  useEffect(() => {
    if (providers) {
      setMaintenanceItems((prevState) => [
        ...prevState,
        {
          title: 'Import Main Pricing data using File Upload',
          subtitle:
            'This will load main pricing data of the selected laboratory.',
          action: inputFile,
          dataName: 'Laboratory',
          data: providers.providers,
          actionButtonName: 'Upload',
        },
      ])
    }
  }, [providers])

  const [uploadFileData, setUploadFileData] = useState()

  useEffect(() => {
    let jsonData = []
    if (uploadFileData) {
      uploadFileData.map((row) => {
        row.test_code = row.test_code.toString()
        jsonData.push(row)
      })
    }
  }, [uploadFileData])

  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)
        setUploadFileData(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  const [selectedProvider, setSelectedProvider] = useState()

  return (
    <div>
      {maintenanceItems?.map((data, index) => {
        return (
          <DataManagementRow
            data={data}
            key={index}
            setSelectedProvider={setSelectedProvider}
          />
        )
      })}

      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={readUploadFile}
        style={{ display: 'none' }}
        accept=".xlsx, .xls, .csv"
      />

      <div
        style={{
          wordBreak: 'break-all',
          paddingBottom: 90,
        }}
      >
        {uploadFileData && (
          <div
            style={{
              display: 'flex',
              backgroundColor: '#ECECEC',
              padding: 10,
              fontSize: 12,
              marginBottom: 10,
            }}
          >
            <div style={{ width: '10%' }}>
              <b>Test Code</b>
            </div>
            <div style={{ width: '51%' }}>
              <b>Test Name</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Cost</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Standard Price</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>Standard Retail Price</b>
            </div>
          </div>
        )}

        {uploadFileData &&
          uploadFileData.map((item, index) => {
            return (
              <ListRow
                key={index}
                className={classnames(sharedStyle.listRow, style.row)}
              >
                <div className={style.firstColumn}>{item.test_code}</div>
                <div className={style.secondColumn}>{item.test_name}</div>

                <div className={style.thirdColumn}>
                  <Form>
                    <Input
                      name="provider_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.provider_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
                <div className={style.fourthColumn}>
                  <Form>
                    <Input
                      name="ls_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.ls_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
                <div className={style.fifthColumn}>
                  <Form>
                    <Input
                      name="standard_retail_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.standard_retail_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
              </ListRow>
            )
          })}
      </div>

      {uploadFileData && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: 20,
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'flex-end',
            width: width * 0.95,
            zIndex: 1,
          }}
        >
          <div>
            This will import <b>{uploadFileData.length}</b> main pricing data to{' '}
            <b style={{ paddingRight: 10 }}>{selectedProvider?.name}</b>
            <Button onClick={() => startImportMainPricing()}>
              Import Data
            </Button>
          </div>
        </div>
      )}

      {modals}
    </div>
  )
}
