import React from 'react'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import style from './MiscPricingList.module.css'
import MiscPricingRow from './MiscPricingRow'

export default function MiscPricingList({
  data,
  fetchMore,
  loading,
  miscPricing,
  provider,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.miscPricing.data
    pagination.page = data.miscPricing.page
    pagination.pages = data.miscPricing.pages
    pagination.total = data.miscPricing.total
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          // {
          //   name: 'Misc Code',
          //   className: style.codeColumn,
          // },
          { name: 'Misc Name', className: style.nameColumn },
          {
            name: 'Lab Contract Price',
            className: style.priceColumn,
          },
          {
            name: 'Patient Retail Price',
            className: style.priceColumn,
          },
        ]}
      />

      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="partner-pricing-list"
        hasMore={hasMore({ page: pagination.page, pages: pagination.pages })}
        next={() =>
          fetchNext('miscPricing', 'data', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && miscPricing.length <= 0 && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No miscellaneous pricings were found under this Laboratory. </b>{' '}
            You may add one using the "Add Miscellaneous" button above.
          </Card>
        )}

        {!isEmpty(items) && (
          <MiscPricingRow
            items={items}
            miscPricing={miscPricing}
            providerId={provider?._id}
          />
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
