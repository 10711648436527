export function isValidCode(value = '') {
  if (!/^\w{0,30}$/.test(value.trim())) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_code',
        defaultMessage: 'Invalid code',
      }),
    )
  }
}
