import React, { useEffect } from 'react'
import { useForm, InputField } from '@labsavvyapp/ui-components'

import { findPostalCode } from './google-maps'

export default function ZipCodeField({
  name,
  label = 'ZIP Code',
  addressFieldNames = [],
}) {
  const { getData, updateField } = useForm()

  useEffect(() => {
    async function fetchPostalCode() {
      // Check if all address fields are filled
      const fieldsAreNotEmpty = addressFieldNames.every(
        (addressField) => !!getData(addressField),
      )

      // Request zip code only if all address fields are all filled in and there
      // was no previous ZIP code
      if (fieldsAreNotEmpty && !getData(name)) {
        const address = addressFieldNames.reduce(
          (addressStr, field) => `${addressStr} ${getData(field)},`,
          '',
        )

        const postalCode = await findPostalCode(address)

        if (postalCode) updateField(name, { value: postalCode })
      }
    }

    fetchPostalCode()
  })

  return <InputField name={name} label={label} />
}
