import React, { useState, useEffect } from 'react'

import { Dropdown } from 'semantic-ui-react'
import { Search, Button, useSavingModals } from '@labsavvyapp/ui-components'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'

import TeamMembersList from './TeamMembersList/TeamMembersList'
import { updateURLParameter } from '../../../../utils/urls'
import { SORT_BY_OPTIONS } from './constants'
import style from './ProjectMembersTab.module.css'
import { PARTNERS } from '../../../../config/routes'
import { ListPartnerProjectTeamMembersTab } from '../../../../graphql/partner/queries.js'
import { DeletePartnerProjectTeamMember } from '../../../../graphql/partner/mutations.js'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = { first: SORT_BY_OPTIONS.nameAZ.value }
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = { first: SORT_BY_OPTIONS.nameZA.value }
      break
    case SORT_BY_OPTIONS.roleAZ.key:
      variables.sort.role = { priority: SORT_BY_OPTIONS.roleAZ.value }
      break
    case SORT_BY_OPTIONS.roleZA.key:
      variables.sort.role = { priority: SORT_BY_OPTIONS.roleZA.value }
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function ProjectMembersTab() {
  const { push } = useHistory()
  const { partnerId, projectId, section } = useParams()

  // Modals
  const [modals, { showConfirmationModal }] = useSavingModals({
    confirmationMessage: 'Are you sure you want to delete this Team Member?',
    savingMessage: "We're executing the operation, please wait...",
    savedMessage: 'Team Member deleted.',
    onConfirm: () => deleteTeamMember(),
  })

  // Olds the value for the team member to be deleted
  // FIXME: This is not the best approach because we will use an effect for the operation.
  // A better approach is to returning a promise form the onYesClick callback
  const [teamMemberToDelete, setTeamMemberToDelete] = useState(null)

  // Read URL parameters in order to populate search and sort filters
  const query = new URLSearchParams(window.location.search)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // Fetch team members list to populate the dropdown
  const {
    data: teamMemberData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(ListPartnerProjectTeamMembersTab, {
    variables: {
      partnerId,
      projectId,
      ...getQueryVariables(sortBy, search),
    },
    notifyOnNetworkStatusChange: true,
  })

  // Refetch data if filters change
  useEffect(() => {
    refetch({
      partnerId,
      projectId,
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, partnerId, projectId, refetch])

  /**
   * Constructs the URL by replacing the partnerId and section with the
   * current tab, and appends the search and sort parameters.
   */
  function navigateToURL(params) {
    const baseURL = PARTNERS.projects.admin.section
      .replace(':partnerId', partnerId)
      .replace(':projectId', projectId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    navigateToURL(urlParams)
    setSearch(value)
  }

  const [deletePartnerProjectTeamMember] = useMutation(
    DeletePartnerProjectTeamMember,
  )

  async function handleDeleteTeamMember(userId) {
    setTeamMemberToDelete(userId)
    showConfirmationModal(true)
  }

  async function deleteTeamMember() {
    await deletePartnerProjectTeamMember({
      variables: { partnerId, projectId, userId: teamMemberToDelete },
      refetchQueries: [
        {
          query: ListPartnerProjectTeamMembersTab,
          variables: {
            partnerId,
            projectId,
            ...getQueryVariables(sortBy, search),
          },
          notifyOnNetworkStatusChange: true,
        },
      ],
    })
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div>
          <span className={style.dropdownLabel}>Sort by:</span>
          <Dropdown
            selection
            className={style.dropdown}
            value={sortBy}
            options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
              text: SORT_BY_OPTIONS[option].text,
              value: SORT_BY_OPTIONS[option].key,
              key: SORT_BY_OPTIONS[option].key,
            }))}
            onChange={handleSortChange}
            data-test="dropdown-sort"
          />
        </div>

        <Search
          className={style.search}
          placeholder="Search by Name"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button
          data-test="button-invite-project-member"
          onClick={() => push('team-members/invite')}
          size={Button.size.small}
        >
          Invite Project Member
        </Button>
      </div>

      <TeamMembersList
        data={camelizeKeys(teamMemberData)}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
        onDeleteClick={handleDeleteTeamMember}
      />

      {/* Modals */}
      {modals}
    </div>
  )
}
