import axios from 'axios'

export async function findPostalCode(address) {
  const queryParams = new URLSearchParams({
    address,
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const { data } = await axios(
    `https://maps.googleapis.com/maps/api/geocode/json?${queryParams.toString()}`,
  )

  const postalCode =
    data.results.length &&
    data.results[0].address_components.find((result) =>
      result.types.includes('postal_code'),
    )

  return postalCode ? postalCode.long_name : null
}
