import React from 'react'
import { Route, Switch } from 'react-router'

import { PROFILE } from '../config/routes'
import ProfilePage from '../pages/Profile/ProfilePage/ProfilePage'
import AccountVerificationPage from '../pages/Profile/AccountVerificationPage/AccountVerificationPage'
import NewPasswordPage from '../pages/Profile/NewPasswordPage/NewPasswordPage'
import PasswordUpdatedPage from '../pages/Profile/PasswordUpdatedPage/PasswordUpdatedPage'

export default function ProfileRoutes({ path }) {
  return (
    <Route
      path={path}
      render={({ match }) => (
        <Switch>
          <Route exact path={match.path} component={ProfilePage} />
          <Route
            exact
            path={PROFILE.accountVerification}
            component={AccountVerificationPage}
          />
          <Route exact path={PROFILE.newPassword} component={NewPasswordPage} />
          <Route
            exact
            path={PROFILE.passwordUpdated}
            component={PasswordUpdatedPage}
          />
        </Switch>
      )}
    />
  )
}
