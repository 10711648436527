import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import { Button } from '@labsavvyapp/ui-components'

import style from './RecoverPassword.module.css'
import logo from '../../../assets/labsavvy-logo.svg'
import { SIGN } from '../../../config/routes'

/**
 * Recover password. Step 4: Finish.
 */
export default function RecoverFinish() {
  const { push } = useHistory()

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column>
        <div className={style.head}>
          <img alt="logo" src={logo} className={style.logo} />
        </div>
        <h3 className={style.title}>Reset password</h3>

        <p className={style.text}>Your password has been changed.</p>

        <Button className={style.loginButton} onClick={() => push(SIGN.in)}>
          Login
        </Button>
      </Grid.Column>
    </Grid>
  )
}
