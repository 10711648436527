import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useHistory } from 'react-router'
import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'

import { Search, Button } from '@labsavvyapp/ui-components'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { PACKAGES } from '../../../config/routes'
import { SORT_BY_OPTIONS } from './constants'
import PackagesList from './PackagesList/PackagesList'
import { updateURLParameter } from '../../../utils/urls'
import { ListProviders } from '../../../graphql/providers/queries.js'
import { ListPackages } from '../../../graphql/packages/queries.js'
import sharedStyle from '../../../styles/shared.module.css'
import style from './PackagesPage.module.css'

export default function PackagesPage() {
  const { search: locationSearch } = useLocation()
  const { push } = useHistory()

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  // Fetch providers
  const { data: providersData, loading: providersLoading } =
    useQuery(ListProviders)

  const providerOptions =
    (providersData &&
      providersData.listProviders.providers.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      }))) ||
    []

  // Set laboratory options (defaults to first entry)
  const [laboratory, setLaboratory] = useState(providerOptions?.[0]?.value)

  useEffect(() => {
    // Set laboratory options (defaults to first entry)
    if (!laboratory) setLaboratory(providerOptions?.[0]?.value)
  }, [providerOptions])

  const getQueryVariables = useCallback(() => {
    const variables = {
      limit: 30,
      sort: {},
      filter: {},
    }

    switch (sortBy) {
      case SORT_BY_OPTIONS.nameAZ.key:
        variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
        break
      case SORT_BY_OPTIONS.nameZA.key:
        variables.sort.name = SORT_BY_OPTIONS.nameZA.value
        break
      case SORT_BY_OPTIONS.createdAtAsc.key:
        variables.sort.created_at = SORT_BY_OPTIONS.createdAtAsc.value
        break
      case SORT_BY_OPTIONS.createdAtDesc.key:
        variables.sort.created_at = SORT_BY_OPTIONS.createdAtDesc.value
        break
      default:
        break
    }

    if (search) {
      variables.filter.name = search
    }

    if (laboratory) {
      variables.filter.provider_id = laboratory
    }

    return variables
  }, [laboratory, search, sortBy])

  // Fetch packages list
  const {
    data: packagesData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(ListPackages, {
    variables: getQueryVariables(),
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      ...getQueryVariables(),
      page: 1,
    })
  }, [sortBy, search, laboratory, getQueryVariables, refetch])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`packages?${urlParams}`)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`packages?${urlParams}`)
    setSearch(value)
  }

  function handleLaboratoryChange(_, { value }) {
    const urlParams = updateURLParameter('laboratory', value)
    push(`packages?${urlParams}`)
    setLaboratory(value)
  }

  return (
    <MainContainer>
      <Helmet>
        <title>Packages</title>
      </Helmet>
      <header className={style.header}>
        <h1 className={classnames(sharedStyle.pageTitle, style.pageTitle)}>
          Packages
        </h1>

        <div className={style.filters}>
          <div>
            <span className={style.dropdownLabel}>Laboratory:</span>
            <Dropdown
              selection
              className={style.laboratoryDropdown}
              loading={providersLoading}
              value={laboratory}
              options={providerOptions}
              onChange={handleLaboratoryChange}
              data-test="laboratory-dropdown"
            />
          </div>

          <div>
            <span className={style.dropdownLabel}>Sort by:</span>
            <Dropdown
              selection
              className={style.searchDropdown}
              value={sortBy}
              options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                text: SORT_BY_OPTIONS[option].text,
                value: SORT_BY_OPTIONS[option].key,
                key: SORT_BY_OPTIONS[option].key,
              }))}
              onChange={handleSortChange}
              data-test="sort-dropdown"
            />
          </div>

          <Search
            className={style.search}
            placeholder="Search Packages"
            showNoResults={false}
            searchText={search}
            onSearchChange={handleSearchChange}
          />

          <Button
            data-test="button-new-package"
            onClick={() => push(PACKAGES.new)}
          >
            New Package
          </Button>
        </div>
      </header>

      <PackagesList
        data={packagesData && packagesData.listPackages}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
      />
    </MainContainer>
  )
}
