import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/react-hooks'
import { Icon, Label, Popup } from 'semantic-ui-react'

import { ListRow, useSavingModals } from '@labsavvyapp/ui-components'
import classnames from 'classnames'

import style from './LabsList.module.css'
import deleteIcon from '../../../../../components/Icons/delete-icon.svg'
import { UpdatePartnerProject } from '../../../../../graphql/partner/mutations'

// import { DeletePartnerProjectProvider } from '../../../../graphql/labs/mutations.js'

export default function LabsListRow({
  items,
  onDeleteClick,
  defaultProvider,
  onSetDefaultLab,
}) {
  const { push } = useHistory()

  const handleEditLab = (provider) => {
    push({ pathname: 'labs/edit-lab', state: { provider: provider } })
  }

  return items.map((item) => (
    <ListRow key={item.provider?.id} className={style.row}>
      <div className={style.providerNameColumn}>
        {item.provider?.name}{' '}
        {defaultProvider?._id === item.provider?.id && (
          <Label color="purple" horizontal size="small">
            API Default
          </Label>
        )}
      </div>
      <div
        className={style.accountNumberColumn}
        onClick={() => handleEditLab(item)}
      >
        {item?.accountNumber}
        <Icon
          name="edit"
          color="purple"
          size="small"
          style={{ marginLeft: 5 }}
        />
      </div>
      {defaultProvider?._id != item.provider?.id ? (
        <div
          className={classnames(style.deleteColumn, style.deleteIcon)}
          style={{ marginLeft: 5, fontSize: 10, color: '#574B96' }}
          onClick={() => onSetDefaultLab(item)}
        >
          SET AS DEFAULT
        </div>
      ) : (
        <div className={classnames(style.deleteColumn, style.deleteIcon)}></div>
      )}

      {defaultProvider?._id != item.provider?.id ? (
        <div
          className={classnames(style.deleteColumn, style.deleteIcon)}
          onClick={() => onDeleteClick(item.provider?.id)}
        >
          <img alt="delete-package" src={deleteIcon} />
        </div>
      ) : (
        <div
          style={{ paddingLeft: 5 }}
          className={classnames(style.deleteColumn, style.deleteIcon)}
        >
          <Popup
            trigger={
              <img
                alt="delete-package"
                src={deleteIcon}
                style={{ opacity: 0.5 }}
              />
            }
            content="You cannot remove a default provider/lab"
            position="left center"
          />
        </div>
      )}
    </ListRow>
  ))
}
