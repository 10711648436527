import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router'
import { Dropdown, Icon, Input, Popup } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'
import _ from 'lodash'
import {
  Button,
  Search,
  Modal,
  Form,
  List,
  ListRow,
} from '@labsavvyapp/ui-components'
import sharedStyle from '../../../styles/shared.module.css'
import style from './MainPricingPage.module.css'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { updateURLParameter } from '../../../utils/urls'
import { GetMainPricing } from '../../../graphql/main-pricing-list/queries.js'
import { GetPricingFormula } from '../../../graphql/main-pricing-list/queries.js'

import MainPricingList from './MainPricingList/MainPricingList'
import { ListProviders } from '../../../graphql/providers/queries'
import { SavePricingFormula } from '../../../graphql/main-pricing-list/mutations'

import { SORT_BY_OPTIONS, SEARCH_BY_OPTIONS } from './constants'

export default function MainPricingPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const [modalOpen, setModalOpen] = useState(false)

  const [provider, setProvider] = useState()
  const [providers, setProviders] = useState()
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS.by_test_code.key)
  const [searchBy, setSearchBy] = useState(SEARCH_BY_OPTIONS.by_test_code.key)
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    SEARCH_BY_OPTIONS.by_test_code.text,
  )

  const [formulas, setFormulas] = useState()

  const [savePricingFormula] = useMutation(SavePricingFormula)

  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })
  useEffect(() => {
    if (providerList?.listProviders && !providers) {
      setProviders(providerList.listProviders.providers)
      setProvider(providerList.listProviders.providers[0])
    }
  }, [providerList, providers])

  const query = new URLSearchParams(locationSearch)
  const [search, setSearch] = useState(query.get('search') || '')

  function getQueryVariables(provider, search, sortBy, searchBy) {
    const variables = {
      limit: 30,
      filter: {
        provider_id: provider?._id || '',
        search: search || '',
        search_by: searchBy || {
          by_test_code: SORT_BY_OPTIONS.by_test_code.value,
        },
      },
      sort: {},
    }

    switch (sortBy) {
      case SORT_BY_OPTIONS.by_test_code.key:
        variables.sort = {
          by_test_code: SORT_BY_OPTIONS.by_test_code.value,
        }
        break
      case SORT_BY_OPTIONS.by_test_name.key:
        variables.sort = {
          by_test_name: SORT_BY_OPTIONS.by_test_name.value,
        }
        break
      default:
        break
    }

    switch (searchBy) {
      case SEARCH_BY_OPTIONS.by_test_code.key:
        variables.filter.search_by = {
          by_test_code: SEARCH_BY_OPTIONS.by_test_code.key,
        }
        break
      case SEARCH_BY_OPTIONS.by_test_name.key:
        variables.filter.search_by = {
          by_test_name: SEARCH_BY_OPTIONS.by_test_name.key,
        }
        break
      default:
        break
    }

    if (search) {
      variables.filter.search = search
    }

    return variables
  }

  function getQueryFormulasVariables(provider) {
    const variables = {
      input: {
        filter: {
          provider_id: provider?._id || '',
        },
      },
    }

    return variables
  }

  const {
    data: pricingListData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(GetMainPricing, {
    variables: { ...getQueryVariables() },
    notifyOnNetworkStatusChange: true,
    skip: !provider,
  })

  const { data: formulasData } = useQuery(GetPricingFormula, {
    variables: getQueryFormulasVariables(provider),
    notifyOnNetworkStatusChange: true,
  })

  if (formulasData?.getPricingFormula && !formulas) {
    setFormulas(formulasData.getPricingFormula.data)
  }
  useEffect(() => {
    if (provider) {
      refetch({
        ...getQueryVariables(provider, search, sortBy, searchBy),
        page: 1,
      })
    }
  }, [provider, search, sortBy, searchBy, refetch])

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, { _id: value })
    setProvider(provider)
  }

  function handleSearchChange(valuelc) {
    const value = valuelc.toUpperCase()
    const urlParams = updateURLParameter('search', value)
    push(`main-pricing?${urlParams}`)
    setSearch(value)
  }

  async function handleFormulaValues(formula, index, e) {
    if (e.target) {
      formula[e.target.name] = parseFloat(e.target.value)
    }

    const copyFormulas = [...formulas]
    copyFormulas[index] = { ...formula }
    await setFormulas(copyFormulas)
  }

  async function handleSaveFormulas() {
    const newRules = formulas.filter(
      (x) => !formulasData?.getPricingFormula?.data.includes(x),
    )

    await savePricingFormula({
      variables: {
        input: {
          provider_id: provider?._id,
          rules: newRules,
        },
      },
    })
    setModalOpen(false)
  }

  async function handleAddFormulas() {
    const copyFormulas = [...formulas]
    copyFormulas.push({
      left: 0,
      right: 0,
      value: 0,
    })
    await setFormulas(copyFormulas)
  }

  function handleSortChange(_, { value }) {
    setSortBy(value)
  }

  function handleSearchByChange(_, { value }) {
    setSearchBy(value)
    handleSearchChange(search)

    setSearchPlaceHolder(SEARCH_BY_OPTIONS[value].text)
  }

  return (
    <MainContainer>
      <Helmet>
        <title>Main Pricing</title>
      </Helmet>
      <header className={style.header}>
        <div style={{ marginTop: 8 }}>
          <h1 className={classnames(sharedStyle.pageTitle, style.pageTitle)}>
            Main Pricing
          </h1>
        </div>

        <div className={style.filters}>
          <span className={style.dropdownLabel}>Laboratory:</span>
          {providers && (
            <div style={{ width: '100' }}>
              <Dropdown
                selection
                options={providers.map((item) => ({
                  text: item.name,
                  value: item._id,
                  key: item._id,
                }))}
                defaultValue={providers[0]._id}
                onChange={handleSetProvider}
                fluid
              />
            </div>
          )}

          <span className={style.dropdownLabel}>Sort by:</span>
          <div style={{ width: '100' }}>
            <Dropdown
              selection
              value={sortBy}
              options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                text: SORT_BY_OPTIONS[option].text,
                value: SORT_BY_OPTIONS[option].key,
                key: SORT_BY_OPTIONS[option].key,
              }))}
              onChange={handleSortChange}
              fluid
            />
          </div>

          <span className={style.dropdownLabel}>Search by:</span>
          <div style={{ width: '100' }}>
            <Dropdown
              selection
              value={searchBy}
              options={Object.keys(SEARCH_BY_OPTIONS).map((option) => ({
                text: SEARCH_BY_OPTIONS[option].text,
                value: SEARCH_BY_OPTIONS[option].key,
                key: SEARCH_BY_OPTIONS[option].key,
              }))}
              onChange={handleSearchByChange}
              fluid
            />
          </div>

          <Search
            className={style.search}
            placeholder={`Search by ` + searchPlaceHolder}
            showNoResults={false}
            searchText={search}
            onSearchChange={handleSearchChange}
          />
        </div>
      </header>

      <MainPricingList
        data={pricingListData}
        provider={provider}
        loading={false}
        fetchMore={fetchMore}
        search={search}
        networkStatus={networkStatus}
      />
      <Modal
        title={'Pricing Formulas'}
        className={style.modal}
        open={modalOpen}
        onCloseClick={() => {
          setModalOpen(false)
        }}
      >
        <List>
          {formulas &&
            formulas.map((item, index) => (
              <div key={index}>
                <ListRow style={{ padding: 0, border: 0 }}>
                  <Form className={style.form}>
                    <span className={style.currency}>
                      <input
                        defaultValue={item.left}
                        className={style.currency}
                        style={{ paddingLeft: '18', textAlign: 'end' }}
                        name="left"
                        type="currency"
                        required={true}
                        onChange={(e) => handleFormulaValues(item, index, e)}
                      />
                    </span>

                    <p className={style.divider}>-</p>
                    <span className={style.currency}>
                      <input
                        className={style.toPrice}
                        style={{ paddingLeft: '18', textAlign: 'end' }}
                        name="right"
                        defaultValue={item.right}
                        type="currency"
                        required={true}
                        onChange={(e) => handleFormulaValues(item, index, e)}
                      />
                    </span>
                    <p className={style.divider}></p>
                    <span className={style.percentage}>
                      <input
                        className={style.percentage}
                        style={{ paddingLeft: '18', textAlign: 'end' }}
                        name="value"
                        defaultValue={item.value}
                        type="currency"
                        required={true}
                        onChange={(e) => handleFormulaValues(item, index, e)}
                      />
                    </span>
                  </Form>
                </ListRow>
              </div>
            ))}
          <Button
            className={style.addButton}
            onClick={() => handleAddFormulas()}
          >
            +
          </Button>
          <div className={style.buttonContainer}>
            <Button
              onClick={() => {
                handleSaveFormulas(false)
              }}
              className={style.saveButton}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setModalOpen(false)
              }}
              variant="basic"
              className={style.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </List>
      </Modal>
    </MainContainer>
  )
}
