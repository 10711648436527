import React from 'react'
import { Switch, Route } from 'react-router'

import { TEAM_MEMBERS } from '../config/routes'
import TeamMembersPage from '../pages/TeamMembers/TeamMembers/TeamMembersPage'
import TeamMemberForm from '../pages/TeamMembers/TeamMemberForm/TeamMemberForm'
import MainLayout from '../components/MainLayout/MainLayout'

export default function TeamMembersRoutes({ logged, user }) {
  return (
    <MainLayout noPadding logged={logged} user={user}>
      <Switch>
        <Route exact path={TEAM_MEMBERS.base} component={TeamMembersPage} />
        <Route exact path={TEAM_MEMBERS.invite} component={TeamMemberForm} />
        <Route exact path={TEAM_MEMBERS.manage} component={TeamMemberForm} />
      </Switch>
    </MainLayout>
  )
}
