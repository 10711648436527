import React from 'react'
import { useParams } from 'react-router'
import { useMutation } from 'react-apollo'
import { Input } from 'semantic-ui-react'
import classnames from 'classnames'
import { ListRow, Form } from '@labsavvyapp/ui-components'

import { ProcessPartnerMiscPricing } from '../../../../../graphql/partner/mutations.js'

import sharedStyle from '../../../../../styles/shared.module.css'
import style from './MiscPricingRow.module.css'

export default function MiscPricingRow({ miscPricing, providerId }) {
  const [processPartnerMiscPricing] = useMutation(ProcessPartnerMiscPricing)
  const { partnerId } = useParams()

  const handleSaveMiscPricing = async (value, e) => {
    if (e.target) {
      value[e.target.name] = parseFloat(e.target.value)
      processPartnerMiscPricing({
        variables: {
          partnerId,
          providerId,
          data: { ...value },
        },
      })
    }
  }

  return miscPricing.map((item, index) => (
    <Form key={item.id} initialFormData={item}>
      <ListRow
        key={item.id}
        className={classnames(sharedStyle.listRow, style.row)}
      >
        {/* <div className={style.firstColumn}></div> */}
        <div className={style.secondColumn}>{item.name}</div>
        <div className={style.formColumn}>
          <Input
            name="contract_price"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={miscPricing[index]?.contract_price}
            required={true}
            onBlur={(e) => handleSaveMiscPricing(item, e)}
            key={`contract_price:${miscPricing[index]?.contract_price || 0}`}
          />
        </div>

        <div className={style.formColumn}>
          <Input
            name="retail_price"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={miscPricing[index]?.retail_price}
            required={true}
            onBlur={(e) => handleSaveMiscPricing(item, e)}
            key={`retail_price:${miscPricing[index]?.retail_price || 0}`}
          />
        </div>
      </ListRow>
    </Form>
  ))
}
