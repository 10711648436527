import React from 'react'

import { useHistory } from 'react-router'
import classnames from 'classnames'

import style from './BackButton.module.css'
import TriangleIcon from '../Icons/TriangleIcon'

export default function BackButton({ className, text = 'back', url }) {
  const { push } = useHistory()

  return (
    <a
      className={classnames(style.container, className)}
      data-test="back-button"
      href={url}
      onClick={(event) => {
        event.preventDefault()
        push(url)
      }}
    >
      <TriangleIcon direction="left" color="#d76acd" opacity="1" />
      <div className={style.text}>{text}</div>
    </a>
  )
}
