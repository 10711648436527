import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'

export default function PlusIcon({
  className,
  color = '#d76acd',
  onClick,
  testId = 'button-plus-icon',
  size = 16,
}) {
  return (
    <svg
      data-test={testId}
      width={size}
      height={size}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(style.icon, className)}
      onClick={onClick}
    >
      <path d="M4 6H0V4h4V0h2v4h4v2H6v4H4V6z" fill={color} fillRule="nonzero" />
    </svg>
  )
}
