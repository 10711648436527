import React from 'react'

import classnames from 'classnames'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import style from './TeamMembersList.module.css'
import TeamMembersListRow from './TeamMembersListRow'

export default function TeamMembersList({
  data,
  fetchMore,
  loading,
  onDeleteClick,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.listPartnerProjectTeamMembers.partnerTeamMembers
    pagination.page = data.listPartnerProjectTeamMembers.page
    pagination.pages = data.listPartnerProjectTeamMembers.pages
    pagination.total = data.listPartnerProjectTeamMembers.total
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Name',
            className: classnames(style.column, style.imageNameColumn),
          },
          {
            name: 'Email/ Username',
            className: classnames(style.column, style.emailColumn),
          },
          {
            name: 'Role',
            className: classnames(style.column, style.roleColumn),
          },
          {
            name: 'Status',
            className: classnames(style.column, style.statusColumn),
          },
          {
            name: '',
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="team-members-list"
        hasMore={hasMore({ page: pagination.page, pages: pagination.pages })}
        next={() =>
          fetchNext('listPartnerTeamMembers', 'partner_team_members', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(items) && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No team members were found. </b> If you feel this is an error,
            you may try to{' '}
            <button
              type="button"
              onClick={() => window.location.reload()}
              className={style.refreshLink}
            >
              refresh page
            </button>{' '}
            &mdash; this may be a momentary issue.
          </Card>
        )}

        {!isEmpty(items) && (
          <TeamMembersListRow items={items} onDeleteClick={onDeleteClick} />
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
