import React from 'react'
import { Switch, Route } from 'react-router'

import { SETTINGS } from '../config/routes'
import SettingsPage from '../pages/Settings/SettingsPage/SettingsPage'
import MainLayout from '../components/MainLayout/MainLayout'

export default function SettingsRoutes({ logged, user }) {
  return (
    <MainLayout noPadding logged={logged} user={user}>
      <Switch>
        <Route exact path={SETTINGS.base} component={SettingsPage} />
        <Route exact path={SETTINGS.maintenance} component={SettingsPage} />
        <Route exact path={SETTINGS.emailManagement} component={SettingsPage} />
        <Route exact path={SETTINGS.dataManagement} component={SettingsPage} />
        <Route exact path={SETTINGS.configuration} component={SettingsPage} />
        <Route
          exact
          path={SETTINGS.addConfiguration}
          component={SettingsPage}
        />
        <Route
          exact
          path={SETTINGS.editConfiguration}
          component={SettingsPage}
        />
      </Switch>
    </MainLayout>
  )
}
