import React from 'react'

import classnames from 'classnames'
import { ListRow, NameInitials, ProfilePhoto } from '@labsavvyapp/ui-components'
import { useHistory, useParams, generatePath } from 'react-router'
import { utc } from 'moment'

import sharedStyle from '../../../../../styles/shared.module.css'
import { PARTNERS } from '../../../../../config/routes'
import style from './TeamMembersList.module.css'
import capitalize from '../../../../../utils/capitalize'

export default function TeamMembersListRow({ items }) {
  const { push } = useHistory()
  const urlParams = useParams()

  return items.map((item) => (
    <ListRow
      data-test="list-row-team-member"
      key={item.id}
      className={classnames(sharedStyle.listRow, style.row)}
      onClick={() =>
        push(
          generatePath(PARTNERS.admin.editTeamMember, {
            ...urlParams,
            teamMemberId: item.id,
          }),
        )
      }
    >
      <div
        className={classnames(style.column, style.imageNameColumn)}
        data-test="team-member-name"
      >
        {item.profile_photo ? (
          <ProfilePhoto
            className={style.memberImage}
            size={35}
            url={item.profilePhoto.url}
          />
        ) : item.name && item.name.first && item.name.last ? (
          <NameInitials
            firstName={item.name.first}
            lastName={item.name.last}
            size={34}
            className={style.memberImage}
          />
        ) : (
          ''
        )}

        {item.name ? item.name.display : ''}
      </div>
      <div className={style.column}>{item.email}</div>
      <div className={style.column} data-test="team-member-role">
        {item.role && item.role.name}
      </div>
      <div className={style.column}>
        {utc(item.joined).format('MM/DD/YYYY')}
      </div>
      <div className={style.column}>{capitalize(item.status) || 'Active'}</div>
    </ListRow>
  ))
}
