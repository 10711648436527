import gql from 'graphql-tag'

// Using team members querie untill princing list query it's done
export const GetMainPricing = gql`
  query (
    $page: PositiveInt
    $filter: MainPricingFilter!
    $sort: MainPricingSort
    $limit: PositiveInt
  ) {
    mainPricing(
      input: { page: $page, filter: $filter, sort: $sort, limit: $limit }
    ) {
      data {
        id
        test_code
        test_name
        is_ls_price_overridden
        provider_price
        ls_price
        standard_retail_price
      }
      page
      pages
      total
    }
  }
`

export const GetPricingFormula = gql`
  query ($input: GetPricingFormulaInput!) {
    getPricingFormula(input: $input) {
      data {
        left
        right
        value
      }
    }
  }
`
