import React from 'react'

import { Button } from '@labsavvyapp/ui-components'

import style from './PlanTab.module.css'

export default function PlanTab() {
  return (
    <div className={style.container}>
      <div className={style.plan}>
        <div className={style.planDescriptionContainer}>
          <div className={style.planTitle}>Strategic Partner Plan</div>
          <div>
            <p className={style.planItem}>Unlimited Projects</p>
            <p className={style.planItem}>Free Branded Landing Pages</p>
            <p className={style.planItem}>Unlimited Team Members</p>
            <p className={style.planItem}>Unlimited Clients</p>
            <p className={style.planItem}>Affiliate Pricing</p>
            <p className={style.planItem}>Monthly Invoicing</p>
          </div>
        </div>
        <Button size={Button.size.small}>Change Plan</Button>
      </div>
    </div>
  )
}
