import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@labsavvyapp/ui-components'

import { HOME } from '../../config/routes'
import logo from '../../assets/logo-white.svg'
import style from './Error404Page.module.css'

function Error404Page() {
  const { push } = useHistory()

  return (
    <div className={style.container}>
      <img className={style.logo} src={logo} alt="logo" />
      <h1 className={style.title}>404 Page not found!</h1>

      <p className={style.text}>
        It means the page you were looking for was not found, was moved or it
        has been removed!
      </p>

      <Button className={style.button} onClick={() => push(HOME)}>
        Go to Homepage
      </Button>
    </div>
  )
}
export default Error404Page
