/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { Search, Button } from '@labsavvyapp/ui-components'
import _ from 'lodash'

import { GetPartnerPricing } from '../../../../graphql/partner-pricing-list/queries'
import { ListPartnerMiscPricing } from '../../../../graphql/partner/queries.js'
import MiscPricingList from '../MiscPricing/MiscPricingList/MiscPricingList'
import { updateURLParameter } from '../../../../utils/urls'
import { SORT_BY_OPTIONS } from './constants'
import style from './MiscPricingTab.module.css'
import { PARTNERS } from '../../../../config/routes'
import { ListProviders } from '../../../../graphql/providers/queries'

function getQueryVariables(sortBy, provider, partnerId, search) {
  const variables = {
    filter: {
      provider_id: provider?._id || '',
      search: search || '',
      partner_id: partnerId || '',
    },
    sort: {},
    limit: 20,
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.by_test_code.key:
      variables.sort = {
        by_test_code: SORT_BY_OPTIONS.by_test_code.value,
      }
      break
    case SORT_BY_OPTIONS.by_test_name.key:
      variables.sort = {
        by_test_name: SORT_BY_OPTIONS.by_test_name.value,
      }
      break
    case SORT_BY_OPTIONS.by_override.key:
      variables.sort = { by_override: SORT_BY_OPTIONS.by_override.value }
      break
    case SORT_BY_OPTIONS.by_price.key:
      variables.sort = { by_price: SORT_BY_OPTIONS.by_price.value }
      break
    default:
      break
  }

  return variables
}

export default function MiscPricingTab() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()
  const { partnerId, section } = useParams()
  const [provider, setProvider] = useState()
  const [providers, setProviders] = useState()
  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  if (providerList?.listProviders && !providers) {
    setProviders(providerList.listProviders.providers)
    setProvider(providerList.listProviders.providers[0])
  }

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.by_test_code.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  const defaultMiscPricing = [
    {
      code: 'LSMC0000000001',
      name: 'Draw Fee',
      contract_price: 0,
      retail_price: 0,
    },
    {
      code: 'LSMC0000000002',
      name: 'Prescriptive Authority',
      contract_price: 0,
      retail_price: 0,
    },
    {
      code: 'LSMC0000000003',
      name: 'Techonology Fee',
      contract_price: 0,
      retail_price: 0,
    },
  ]
  const [miscPricing, setMiscPricing] = useState(defaultMiscPricing)

  const {
    data: listPartnerMiscPricing,
    fetchMore,
    refetch,
    loading,
  } = useQuery(ListPartnerMiscPricing, {
    variables: {
      partnerId: partnerId,
      providerId: provider ? provider?._id : '608c378c4dd9e90010bad905',
    },
  })

  const partnerMiscPricing =
    (listPartnerMiscPricing &&
      listPartnerMiscPricing.listPartnerMiscPricing.misc_pricing.map(
        ({ name, code, contract_price, retail_price }) => ({
          name: name,
          code: code,
          contract_price: contract_price,
          retail_price: retail_price,
        }),
      )) ||
    []

  useEffect(() => {
    if (!loading) {
      const saved_misc_pricing =
        listPartnerMiscPricing.listPartnerMiscPricing.misc_pricing

      // cross check default pricing from db
      const remnants = defaultMiscPricing.filter(
        (d) => !saved_misc_pricing.some((m) => d.name === m.name),
      )

      const updated_pricing = [...saved_misc_pricing, ...remnants]
      updated_pricing.sort((a, b) => a.code - b.code)

      refetch()
      if (miscPricing.length !== updated_pricing.length) {
        setMiscPricing(updated_pricing)
      }
    }
  }, [
    listPartnerMiscPricing,
    defaultMiscPricing,
    miscPricing,
    loading,
    refetch,
  ])

  const MISC_PRICING = {
    miscPricing: {
      data: miscPricing,
    },
  }

  function navigateToURL(params) {
    const baseURL = PARTNERS.admin.section
      .replace(':partnerId', partnerId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    setSearch(value)
  }

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, { _id: value })
    setProvider(provider)
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div className={style.tools}>
          {providers && (
            <>
              <div className={style.dropdownLabel}>Laboratory:</div>
              <Dropdown
                selection
                className={style.dropdown}
                defaultValue={provider?._id}
                options={providers.map((item) => ({
                  text: item.name,
                  value: item._id,
                  key: item._id,
                }))}
                onChange={handleSetProvider}
              />
            </>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            data-test="button-add-misc-pricing"
            onClick={() => push('misc-pricing/add')}
            size={Button.size.small}
          >
            Add Miscellaneous
          </Button>
        </div>
      </div>

      <MiscPricingList
        data={MISC_PRICING}
        miscPricing={partnerMiscPricing}
        loading={loading}
        fetchMore={fetchMore}
        provider={provider}
      />
    </div>
  )
}
