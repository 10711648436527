import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Dropdown } from 'semantic-ui-react'
import {
  Modal,
  Search,
  InfiniteScrollList,
  Button,
} from '@labsavvyapp/ui-components'

import style from './TestGroupSelectionPanel.module.css'
import { GroupDetails } from './GroupDetails'
import { GroupListItem } from './GroupListItem'
import { TestListItem } from './TestListItem'

const TEST_OPTIONS = [
  { key: 'group', value: 'group', text: 'By Groups' },
  { key: 'individual', value: 'individual', text: 'By Tests' },
]

const GROUPS_TEST_OPTION_VALUE = 'group'
const GROUPS_SEARCH_INPUT_PLACEHOLDER = 'Search Groups'
const GROUPS_ADD_BUTTON_TEXT = 'Group'

const TESTS_SEARCH_INPUT_PLACEHOLDER = 'Search Tests'
const TESTS_ADD_BUTTON_TEXT_SINGULAR = 'Test'
const TESTS_ADD_BUTTON_TEXT_PLURAL = 'Tests'

const TestGroupSelectionPanel = ({
  tests,
  groups,
  className,
  fetchNext,
  hasMore,
  onTypeChange,
  onSearchChange,
  onCancelClick,
  onAddClick,
  open = true,
}) => {
  const [selectedTypeOption, setSelectedTypeOption] = useState(
    GROUPS_TEST_OPTION_VALUE,
  )
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    GROUPS_SEARCH_INPUT_PLACEHOLDER,
  )

  const [searchValue, setSearchValue] = useState()

  const [selectedGroup, setSelectedGroup] = useState()
  const [addedGroups, setAddedGroups] = useState([])
  const [groupDetails, setGroupDetails] = useState()

  const [selectedTests, setSelectedTests] = useState([])
  const [addedTests, setAddedTests] = useState([])

  useEffect(() => {
    onSearchChange({ type: selectedTypeOption, value: searchValue })
  }, [searchValue, onSearchChange, selectedTypeOption])

  //Update added tests, they can be removed from the application side.
  useEffect(() => {
    const flagGroup = groups.some((group) => group.code === selectedGroup)
    if (selectedTypeOption === GROUPS_TEST_OPTION_VALUE && !flagGroup) {
      setSelectedGroup()
      setGroupDetails()
    }
    const newAddedTests = tests.reduce((newTests, test) => {
      if (test.selected) {
        newTests.push(test)
      }

      return newTests
    }, [])

    setAddedTests(newAddedTests)
  }, [tests, groups, selectedGroup, selectedTypeOption])

  const handleTypeChange = (_, { value }) => {
    if (value === GROUPS_TEST_OPTION_VALUE) {
      setSearchPlaceholder(GROUPS_SEARCH_INPUT_PLACEHOLDER)
      setSelectedGroup()
    } else {
      setSearchPlaceholder(TESTS_SEARCH_INPUT_PLACEHOLDER)
      setSelectedTests([])
    }

    setSelectedTypeOption(value)
    onTypeChange && onTypeChange(value)
  }

  const handleGroupClick = ({ code, name, tests }) => {
    setSelectedGroup(code)

    setGroupDetails({ code, name, tests })
  }

  const handleTestClick = (test) => {
    const testIndex = selectedTests.findIndex(({ id }) => id === test.id)

    if (testIndex !== -1) {
      const newTests = selectedTests.slice()
      newTests.splice(testIndex, 1)
      setSelectedTests(newTests)
    } else {
      setSelectedTests((tests) => [...tests, test])
    }
  }

  const handleCancelClick = () => {
    setSelectedTests([])
    setSelectedGroup()

    onCancelClick()
  }

  const handleAddClick = () => {
    if (selectedTypeOption === GROUPS_TEST_OPTION_VALUE) {
      const newAddedGroups = [...addedGroups, groupDetails.id]

      onAddClick({
        type: selectedTypeOption,
        value: [groupDetails],
      })
      setAddedGroups(newAddedGroups)
      setSelectedGroup()
    } else {
      const newAddedTests = [...addedTests, ...selectedTests]

      onAddClick({
        type: selectedTypeOption,
        value: selectedTests.map((test) => ({
          code: '',
          name: 'Individual Tests',
          tests: [test],
        })),
      })
      setAddedTests(newAddedTests)
      setSelectedTests([])
    }
  }

  const items = selectedTypeOption === GROUPS_TEST_OPTION_VALUE ? groups : tests

  return (
    <Modal
      open={open}
      showClose={false}
      className={style.modal}
      onCloseClick={() => {}}
    >
      <div className={classnames(style.container, className)}>
        <div className={style.filters}>
          <Dropdown
            selection
            className={style.dropdown}
            value={selectedTypeOption}
            options={TEST_OPTIONS}
            onChange={handleTypeChange}
            data-test="test-type-dropdown"
          />
          <Search
            className={style.search}
            placeholder={searchPlaceholder}
            showNoResults={false}
            onSearchChange={(search) => setSearchValue(search)}
            useAutoCorrect={false}
          />
        </div>

        <div className={style.listDetailsContainer}>
          <div
            className={classnames(style.list, {
              [style.listFullWidth]:
                selectedTypeOption !== GROUPS_TEST_OPTION_VALUE,
            })}
          >
            <InfiniteScrollList
              dataLength={items.length}
              scrollableTarget="some-name"
              hasMore={hasMore}
              next={fetchNext}
              saveScrollPosition={false}
            >
              {items.map((item) =>
                selectedTypeOption === GROUPS_TEST_OPTION_VALUE ? (
                  <GroupListItem
                    key={item.code}
                    item={item}
                    selected={item.code === selectedGroup}
                    disabled={item.selected}
                    onClick={handleGroupClick}
                  />
                ) : (
                  <TestListItem
                    key={item.tests[0].id}
                    item={item}
                    selected={
                      selectedTests.findIndex(
                        ({ id }) => id === item.tests[0].id,
                      ) !== -1
                    }
                    disabled={item.selected}
                    onClick={handleTestClick}
                  />
                ),
              )}
            </InfiniteScrollList>
          </div>

          {selectedTypeOption === GROUPS_TEST_OPTION_VALUE && (
            <GroupDetails group={groupDetails} />
          )}
        </div>
        <div className={style.buttons}>
          <Button
            className={style.cancelButton}
            variant={Button.variant.basic}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            className={style.addGroupButton}
            onClick={handleAddClick}
            disabled={
              selectedTypeOption === GROUPS_TEST_OPTION_VALUE
                ? !selectedGroup
                : selectedTests.length === 0
            }
          >
            Add{' '}
            {selectedTypeOption === GROUPS_TEST_OPTION_VALUE
              ? GROUPS_ADD_BUTTON_TEXT
              : selectedTests.length > 1
              ? TESTS_ADD_BUTTON_TEXT_PLURAL
              : TESTS_ADD_BUTTON_TEXT_SINGULAR}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TestGroupSelectionPanel
