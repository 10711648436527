import React from 'react'

export default function CrossIcon({
  color = '#fe3824',
  testId = 'icon-cross',
  size = '16',
}) {
  return (
    <svg
      data-test={testId}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="square"
        strokeWidth="2"
      >
        <path d="M13.657 13.657L2.343 2.343M13.657 2.343L2.343 13.657" />
      </g>
    </svg>
  )
}
