import React from 'react'
import { Switch, Route } from 'react-router'

import MainLayout from '../components/MainLayout/MainLayout'
import { PACKAGE_CATEGORIES } from '../config/routes'
import PackageCategoriesPage from '../pages/PackageCategories/PackageCategoriesPage/PackageCategoriesPage'
import PackageCategoriesFormPage from '../pages/PackageCategories/PackageCategoriesFormPage/PackageCategoriesFormPage'

export default function PackageCategoriesRoutes({ logged, user }) {
  return (
    <Route
      path={PACKAGE_CATEGORIES.base}
      render={({ match }) => (
        <MainLayout noPadding logged={logged} user={user}>
          <Switch>
            <Route exact path={match.path} component={PackageCategoriesPage} />

            <Route
              exact
              path={PACKAGE_CATEGORIES.new}
              component={PackageCategoriesFormPage}
            />

            <Route
              exact
              path={PACKAGE_CATEGORIES.edit}
              component={PackageCategoriesFormPage}
            />
          </Switch>
        </MainLayout>
      )}
    />
  )
}
