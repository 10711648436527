import React from 'react'

import style from './GroupDetails.module.css'

export const GroupDetails = ({ group }) => {
  return (
    <div className={style.details} data-test="group-details">
      {group && (
        <>
          <div className={style.title}>Details</div>
          <div className={style.subTitle}>Tests in this group:</div>
          <div className={style.list}>
            {group.tests.map(({ id, result }) => (
              <div key={id} className={style.listItem}>
                <span className={style.code}>{result.code}</span>
                <span>{result.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
