import gql from 'graphql-tag'

export const AddPartnerProjectProvider = gql`
  mutation AddPartnerProjectProvider(
    $partnerId: MongoID!
    $projectId: MongoID!
    $providerId: MongoID!
    $accountNumber: String
  ) {
    addPartnerProjectProvider(
      partner_id: $partnerId
      project_id: $projectId
      provider_id: $providerId
      account_number: $accountNumber
    ) {
      _id
    }
  }
`

export const DeletePartnerProjectProvider = gql`
  mutation DeletePartnerProjectProvider(
    $partnerId: MongoID!
    $projectId: MongoID!
    $providerId: MongoID!
  ) {
    deletePartnerProjectProvider(
      partner_id: $partnerId
      project_id: $projectId
      provider_id: $providerId
    )
  }
`
