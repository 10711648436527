import React from 'react'
import classnames from 'classnames'

import {
  InfiniteScrollList,
  List,
  ListLoader,
  ListRow,
} from '@labsavvyapp/ui-components'
import { fetchNext, hasMore } from '@labsavvyapp/ui-components/lib/utils'

import style from './CategoriesList.module.css'

export default function CategoriesList({
  data,
  loading,
  fetchMore,
  selected,
  onClick,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
  }

  if (data) {
    items = data.listTestReferenceCategories.testReferenceCategories
    pagination.page = data.listTestReferenceCategories.page
    pagination.pages = data.listTestReferenceCategories.pages
  }

  const handleCategoryClick = (category) => {
    onClick(category)
  }

  return (
    <List>
      <InfiniteScrollList
        scrollableTarget="TestsReferencesCategoriesList"
        dataLength={items.length}
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('listTestReferenceCategories', 'testReferenceCategories', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {items.map((category) => (
          <ListRow
            key={category.id}
            bordered={false}
            className={classnames(style.row, {
              [style.selected]: selected && selected.id === category.id,
            })}
            data-test="tests-references-category-item"
            onClick={() => handleCategoryClick(category)}
          >
            {category.name}
          </ListRow>
        ))}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
